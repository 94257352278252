import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitleProps, toggleConfirmLeaveModal } from '../../../../../actions/app';
import { Button, Input } from '../../../../../ui-kit';
import { CodeVerificationInput } from '../../../../../ui-kit/code-verification-input';
import { ApiClient } from '../../../../../utils/api-client';
import postToNativeApp from '../../../../../utils/postToNativeApp';
import ActionSheet from '../../../../common/ActionSheet';
import { useAsyncStatus, useOpenNewPage, useUser } from '../../../../common/hooks';
import { getCheckFactorAsync, sendCheckFactorAsync } from '../../../../../actions/services';
import AlertBlock from '../../../../common/AlertBlock';

const ActionSheetFooter = ({ onCancel, goBack }) => (
  <div className="footer action-sheet-design">
    <Button className="action-button red-color" onClick={goBack} design="red" size="l">Завершить операцию</Button>
    <Button className="action-button cancellation " onClick={onCancel} design="grey" size="l">Отмена</Button>
  </div>
);

const isTekoDevOrLocal = window.location.origin.includes('localhost') || window.location.origin.includes('rusfinance-web-dev.teko.io');

const CodeVerificationPage = (props) => {
  const { history, match } = props;
  const { params: { creditId } } = match;
  const dispatch = useDispatch();
  const intervalRef = useRef();
  const user = useUser();

  const [code, setCode] = useState('');
  const [timeLeft, setTimeLeft] = useState(60);
  const [login, setLogin] = useState(''); //for test
  const [testCodeResult, setTestCodeResult] = useState(''); //for test

  const { mobile_phone } = user;
  const isIos = window.sessionStorage.getItem('isIos');
  const confirmLeaveModalActive = useSelector((state) => state.app.confirmLeaveModalActive);

  const getCheckFactorStatus = useAsyncStatus((state) => state.services.getCheckFactorStatus);
  const sendCheckFactorStatus = useAsyncStatus((state) => state.services.sendCheckFactorStatus);

  const errorCode = getCheckFactorStatus.error?.error_code;
  const errorMessage = useMemo(() => {
    if ([1001, 1002, 1003].includes(errorCode)) {
      return getCheckFactorStatus.error.error_description;
    }
    return '';
  }, [getCheckFactorStatus.error]);

  const formattedPhone = useMemo(() => (
    mobile_phone.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 *** ** $5')
  ), [mobile_phone]);

  const startTimer = useCallback(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev < 1) {
          clearInterval(intervalRef.current);
          setCode('');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, []);

  const sendCode = useCallback(() => {
    dispatch(getCheckFactorAsync({ confirmationCode: code }));
  }, [code]);

  const resendCode = useCallback(() => {
    setCode('');
    dispatch(sendCheckFactorAsync());
  }, []);

  const onChangeCode = useCallback((e) => {
    setCode(e.target.value);
  }, []);

  const onGoBack = useCallback(() => {
    postToNativeApp({ type: 'close' });
    history.push(`/credits/${creditId}`);

    dispatch(toggleConfirmLeaveModal(false));
  }, [history, creditId]);

  const onCloseModal = useCallback(() => {
    dispatch(toggleConfirmLeaveModal(false));
  }, []);

  const getTestCode = useCallback(() => {
    ApiClient.services.getConfirmCode(login)
      .then((res) => setTestCodeResult(res.data || 'пусто или ошибка, смотрите в network'))
      .catch((error) => setTestCodeResult(error));// only for testing
  }, [login]);

  useEffect(() => {
    if (sendCheckFactorStatus.state === 'initial' || sendCheckFactorStatus.success) {
      setTimeLeft(60);
      startTimer();
    }

    return () => clearInterval(intervalRef.current);
  }, [sendCheckFactorStatus.success]);

  useOpenNewPage();

  useEffect(() => {
    dispatch(setPageTitleProps({ onBackClick: () => dispatch(toggleConfirmLeaveModal(true)) }));

    return () => {
      dispatch(setPageTitleProps({}));
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="code-verification-page">
      <div className={classnames('code-verification-content', { 'is-ios': isIos })}>
        <div className="page-content">
          <p className="description">
            Введите код подтверждения,<br />отправленный на&nbsp;ваш номер<br />{formattedPhone}
          </p>
          <CodeVerificationInput
            value={code}
            onChange={onChangeCode}
            submitForm={sendCode}
            loading={getCheckFactorStatus.loading}
            errorMessage={errorMessage}
            state={errorMessage && code.length === 4 ? 'invalid' : 'valid'}
          />
          {!getCheckFactorStatus.loading && errorCode !== 1003 && (
            <>
              <button
                className="resend-code"
                disabled={timeLeft > 0}
                onClick={resendCode}
              >
                Отправить код повторно
              </button>
              {timeLeft > 0 && <div className="timer">{timeLeft} с</div>}
            </>
          )}
          {errorCode === 1003 && (
            <AlertBlock
              text="Вы трижды ввели код из СМС неверно. Пожалуйста, вернитесь назад и повторите операцию"
              status="warning"
              margin="12px 16px"
            />
          )}
        </div>
      </div>

      {isTekoDevOrLocal && (
        <div className="test-code-wrapper">
          <h3 className="test-code-title">Получение кода для тестирования</h3>
          <Input design="v2" label="Старый РБ логин/рфб (запрашивать у РБ)" value={login} onChange={(e) => setLogin(e.target.value)} />
          <Button className="test-code-btn" design="grey" onClick={getTestCode}>Получить код</Button>
          {testCodeResult && (
            <span className="test-code-result">Ответ: {JSON.stringify(testCodeResult)}</span>
          )}
        </div>
      )}

      <ActionSheet
        active={confirmLeaveModalActive}
        onClose={onCloseModal}
        header="Вы действительно хотите завершить операцию?"
        footer={<ActionSheetFooter onCancel={onCloseModal} goBack={onGoBack} />}
      >
        Если вы остановите операцию, данные не&nbsp;сохранятся
      </ActionSheet>
    </div>
  );
};

export default memo(CodeVerificationPage);
