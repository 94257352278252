import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showFailedToLoadData } from './app';

export const getNotSignedSupAgreement = createAsyncAction(
  'GET_NOT_SIGNED_SUP_AGREEMENT_REQUEST',
  'GET_NOT_SIGNED_SUP_AGREEMENT_SUCCESS',
  'GET_NOT_SIGNED_SUP_AGREEMENT_FAIL',
)();

export function getNotSignedSupAgreementAsync(contractNumber, forceUpdate = false) {
  return (dispatch, getState) => {
    if (Object.keys(getState().services.byKey).length > 0 && !forceUpdate) {
      return;
    }

    dispatch(getNotSignedSupAgreement.request());

    ApiClient.services.getNotSignedSupAgreement({ contractNumber })
      .then(({ data }) => {
        dispatch(getNotSignedSupAgreement.success(data));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        dispatch(getNotSignedSupAgreement.failure(error));
        dispatch(showFailedToLoadData({
          active: true,
          onRetry: () => dispatch(getNotSignedSupAgreementAsync(contractNumber, forceUpdate)),
          asyncStatusReducerName: 'getNotSignedSupAgreementStatus',
          reducersNS: 'services',
          withPageTitle: true,
          title: 'Услуги и опции',
        }));
      });
  };
}

export const getServiceFinProtect = createAsyncAction(
  'GET_SERVICE_FIN_PROTECT_REQUEST',
  'GET_SERVICE_FIN_PROTECT_SUCCESS',
  'GET_SERVICE_FIN_PROTECT_FAIL',
)();

const getFlatServicesWithType = (data) => (
  ['availableFinServiceList', 'registredFinProtecServiceList', 'prolongationFinServiceList']
    .flatMap((type) => {
      const shortType = type.replace('Protec', '').replace('FinServiceList', '');
      return (data[type] ?? []).map((service) => ({
        ...service, key: `${service.id}_${shortType}${service.statusCode ? `_${service.statusCode?.toLowerCase()}` : ''}`, type: shortType,
      }));
      // type: oneOf(['available', 'registred', 'prolongation'])
    })
);

export function getServiceFinProtectAsync(creditId, forceUpdate = false) {
  return (dispatch, getState) => {
    const getServiceActAvail = (services) => {
      const failedServices = [];
      const filteredServices = services.filter(({ type, statusCode }) => (
        (type === 'registred' || type === 'prolongation') && !['FINISHED', 'COMPLETED', 'CANCELED'].includes(statusCode)
      ));

      const arrayOfGetServicePromises = filteredServices.reduce((acc, { id }) => {
        acc.push(ApiClient.services.getServiceFinProtectActAvail({ contractNumber: creditId, serviceId: id }));
        return acc;
      }, []);

      return Promise.allSettled(arrayOfGetServicePromises)
        .then((arrayOfDetails) => {
          let areAllSuccessfullyFetched = true;
          arrayOfDetails.forEach(({ status, value: { data } = {} }, i) => {
            filteredServices[i] = { ...filteredServices[i], ...data };

            if (status === 'rejected') {
              failedServices.push(filteredServices[i]);
              areAllSuccessfullyFetched = false;
            }
          });

          if (areAllSuccessfullyFetched) {
            dispatch(showFailedToLoadData({}));
            return dispatch(getServiceFinProtect.success([...services, ...filteredServices], { creditId }));
          }

          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getServiceFinProtectAsync(creditId)),
            asyncStatusReducerName: 'fetchStatus',
            reducersNS: 'services',
            withPageTitle: true,
            title: 'Услуги и опции',
          }));

          return dispatch(getServiceFinProtect.failure({ data: [...services, ...filteredServices], failedServices }, { creditId }));
        })
        .catch((error) => dispatch(getServiceFinProtect.failure(error)));
    };

    const prevError = getState().services.fetchStatus.error;
    dispatch(getServiceFinProtect.request(creditId));

    if (prevError?.failedServices?.length > 0 && !forceUpdate) {
      return getServiceActAvail(prevError.failedServices);
    }

    const isNotSignedServicesLength = !!getState().services.notSignedServices?.length;

    return ApiClient.services.getServiceFinProtect({ contractNumber: creditId })
      .then(({ data, status }) => {
        if (status === 204) {
          dispatch(getServiceFinProtect.failure({ data: [], status }));
          return dispatch(showServicesInformer({
            pageTitle: 'Услуги и опции',
            title: 'Услуги и опции отсутствуют',
            descriptions: ['По вашему договору нет&nbsp;доступных для&nbsp;подключения услуг и опций'],
            active: true,
            buttons: [{
              design: 'secondary',
              label: 'Закрыть',
              action: 'onClose',
            }],
          }));
        }
        const services = getFlatServicesWithType(data);
        if (data.registredFinProtecServiceList?.length > 0 || data.prolongationFinServiceList?.length > 0) {
          return getServiceActAvail(services);
        }
        return dispatch(getServiceFinProtect.success(services, { creditId }));
      })
      .catch((error) => {
        dispatch(getServiceFinProtect.failure(error));
        if (error.status === 204) {
          dispatch(showServicesInformer({
            pageTitle: 'Услуги и опции',
            title: 'Услуги и опции отсутствуют',
            descriptions: ['По вашему договору нет&nbsp;доступных для&nbsp;подключения услуг и опций'],
            active: true,
            type: 'error',
            buttons: [{
              design: 'secondary',
              label: 'Закрыть',
              action: 'onClose',
            }],
          }));
        } else if (!isNotSignedServicesLength) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getServiceFinProtectAsync(creditId)),
            asyncStatusReducerName: 'fetchStatus',
            reducersNS: 'services',
            withPageTitle: true,
            title: 'Услуги и опции',
          }));
        }
      });
  };
}

export const getSupAgreement = createAsyncAction(
  'GET_SUP_AGREEMENT_REQUEST',
  'GET_SUP_AGREEMENT_SUCCESS',
  'GET_SUP_AGREEMENT_FAIL',
)();

export function getSupAgreementAsync(params) {
  return (dispatch) => {
    dispatch(getSupAgreement.request());

    ApiClient.services.getSupAgreement(params)
      .then(({ data }) => dispatch(getSupAgreement.success(data)))
      .catch((error) => dispatch(getSupAgreement.failure(error)));
  };
}

export const sendSupAgreement = createAsyncAction(
  'SEND_SUP_AGREEMENT_REQUEST',
  'SEND_SUP_AGREEMENT_SUCCESS',
  'SEND_SUP_AGREEMENT_FAIL',
)();

export function sendSupAgreementAsync(params) {
  return (dispatch) => {
    dispatch(sendSupAgreement.request());

    ApiClient.services.sendSupAgreement(params)
      .then((response) => dispatch(sendSupAgreement.success(response, params)))
      .catch((error) => dispatch(sendSupAgreement.failure(error)));
  };
}

export const getCheckFactor = createAsyncAction(
  'GET_CHECK_FACTOR_REQUEST',
  'GET_CHECK_FACTOR_SUCCESS',
  'GET_CHECK_FACTOR_FAIL',
)();

export function getCheckFactorAsync(params) {
  return (dispatch) => {
    dispatch(getCheckFactor.request());

    ApiClient.services.getCheckFactor(params)
      .then((data) => {
        dispatch(getCheckFactor.success(data));
        dispatch(showFailedToLoadData({}));
        dispatch(showServicesInformer({
          pageTitle: 'Услуги и опции',
          title: 'Документ подписан',
          descriptions: ['Отследить статус заявки&nbsp;можно в&nbsp;журнале&nbsp;обращений', 'Чтобы воспользоваться опцией после подключения, активируйте ее в разделе «Услуги и опции»'],
          active: true,
          type: 'success',
          buttons: [{
            design: 'red',
            label: 'Перейти в журнал обращений',
            action: 'onGotoInquiries',
          }, {
            design: 'secondary',
            label: 'Вернуться к кредиту',
            action: 'onClose',
          }],
        }));
      })
      .catch((error) => {
        dispatch(getCheckFactor.failure(error));

        if ((![1001, 1002, 1003].includes(error?.error_code))) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getCheckFactorAsync(params)),
            asyncStatusReducerName: 'getCheckFactorStatus',
            reducersNS: 'services',
            withPageTitle: true,
            title: 'Услуги и опции',
          }));
        }
      });
  };
}

export const sendCheckFactor = createAsyncAction(
  'SEND_CHECK_FACTOR_REQUEST',
  'SEND_CHECK_FACTOR_SUCCESS',
  'SEND_CHECK_FACTOR_FAIL',
)();

export function sendCheckFactorAsync(params) {
  return (dispatch) => {
    dispatch(sendCheckFactor.request());

    ApiClient.services.sendCheckFactor(params)
      .then(({ data }) => dispatch(sendCheckFactor.success(data)))
      .catch((error) => dispatch(sendCheckFactor.failure(error)));
  };
}

export const finProtectRegistration = createAsyncAction(
  'SERVICES_FIN_PROTECT_REGISTRATION_REQUEST',
  'SERVICES_FIN_PROTECT_REGISTRATION_SUCCESS',
  'SERVICES_FIN_PROTECT_REGISTRATION_FAIL',
)();

export function finProtectRegistrationAsync(data) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(finProtectRegistration.request());

    ApiClient.services.finProtectRegistration({ ...data, requestChannel })
      .then((response) => dispatch(finProtectRegistration.success(response.data, data)))
      .catch((error) => dispatch(finProtectRegistration.failure(error)));
  };
}

export const calcNextPaymentDate = createAsyncAction(
  'SERVICES_CALC_NEXT_PAYMENT_DATE_REQUEST',
  'SERVICES_CALC_NEXT_PAYMENT_DATE_SUCCESS',
  'SERVICES_CALC_NEXT_PAYMENT_DATE_FAIL',
)();

export function calcNextPaymentDateAsync(params) {
  return (dispatch) => {
    dispatch(calcNextPaymentDate.request());

    ApiClient.services.calcNextPaymentDate(params)
      .then(({ data }) => dispatch(calcNextPaymentDate.success(data, { params })))
      .catch((error) => dispatch(calcNextPaymentDate.failure(error)));
  };
}

export const sendPrePaymentSchedule = createAsyncAction(
  'SERVICES_SEND_PRE_PAYMENT_SCHEDULE_REQUEST',
  'SERVICES_SEND_PRE_PAYMENT_SCHEDULE_SUCCESS',
  'SERVICES_SEND_PRE_PAYMENT_SCHEDULE_FAIL',
)();

export function sendPrePaymentScheduleAsync(data) {
  return (dispatch) => {
    dispatch(sendPrePaymentSchedule.request());

    ApiClient.services.sendPrePaymentSchedule(data)
      .then((response) => dispatch(sendPrePaymentSchedule.success(response.data, data)))
      .catch((error) => dispatch(sendPrePaymentSchedule.failure(error)));
  };
}

export const finProtectActivation = createAsyncAction(
  'SERVICES_FIN_PROTECT_ACTIVATION_REQUEST',
  'SERVICES_FIN_PROTECT_ACTIVATION_SUCCESS',
  'SERVICES_FIN_PROTECT_ACTIVATION_FAIL',
)();

export function finProtectActivationAsync(data) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(finProtectActivation.request());
    const serviceType = (data.serviceId === 234 || data.serviceId === 240) ? 'услуги' : 'опции';

    ApiClient.services.finProtectActivation({ ...data, requestChannel })
      .then((response) => {
        dispatch(finProtectActivation.success(response.data, data));
        dispatch(showServicesInformer({
          pageTitle: 'Услуги и опции',
          title: 'Заявка принята',
          descriptions: [`Заявка на активацию ${serviceType}<br />«${data.serviceName}» принята`, 'Отследить статус заявки можно в&nbsp;журнале обращений'],
          active: true,
          type: 'success',
          downloadLabel: 'Заявление',
          downloadLink: response.data.request_document_mobile_url,
          buttons: [{
            design: 'red',
            label: 'Перейти в журнал обращений',
            action: 'onGotoInquiries',
          }, {
            design: 'secondary',
            label: 'Вернуться к кредиту',
            action: 'onClose',
          }],
        }));
      })
      .catch((error) => {
        dispatch(finProtectActivation.failure(error));

        if (error.status === 403 && error.error_code === 3) {
          dispatch(showServicesInformer({
            pageTitle: 'Услуги и опции',
            title: 'Операция отклонена',
            descriptions: [error.error_description],
            active: true,
            type: 'error',
            buttons: [{
              design: 'red',
              label: 'Перейти в журнал обращений',
              action: 'onGotoInquiries',
            }, {
              design: 'secondary',
              label: 'Вернуться к кредиту',
              action: 'onClose',
            }],
          }));
        }
      });
  };
}

export const finProtectRefuse = createAsyncAction(
  'FIN_PROTECT_REFUSE_REQUEST',
  'FIN_PROTECT_REFUSE_SUCCESS',
  'FIN_PROTECT_REFUSE_FAIL',
)();

export function finProtectRefuseAsync(config) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(finProtectRefuse.request());

    const serviceType = (config.serviceId === 234 || config.serviceId === 240) ? 'услуги' : 'опции';

    ApiClient.services.sendServiceFinProtectRefuse({ ...config, requestChannel })
      .then((response) => {
        dispatch(finProtectRefuse.success(response.data, config));
        dispatch(showServicesInformer({
          pageTitle: 'Услуги и опции',
          title: 'Заявка принята',
          descriptions: [`Заявка на отказ от ${serviceType}<br />«${config.serviceName}» принята`, 'Отследить статус заявки можно в&nbsp;журнале обращений'],
          active: true,
          type: 'success',
          downloadLabel: 'Заявление',
          downloadLink: response.data.request_document_mobile_url,
          buttons: [{
            design: 'red',
            label: 'Перейти в журнал обращений',
            action: 'onGotoInquiries',
          }, {
            design: 'secondary',
            label: 'Вернуться к кредиту',
            action: 'onClose',
          }],
        }));
      })
      .catch((error) => {
        dispatch(finProtectRefuse.failure(error));
        if (error.status === 403 && error.error_code === 3) {
          dispatch(showServicesInformer({
            pageTitle: 'Услуги и опции',
            title: 'Операция отклонена',
            descriptions: [error.error_description],
            active: true,
            type: 'error',
            buttons: [{
              design: 'red',
              label: 'Перейти в журнал обращений',
              action: 'onGotoInquiries',
            }, {
              design: 'secondary',
              label: 'Вернуться к кредиту',
              action: 'onClose',
            }],
          }));
        }
      });
  };
}

export const resetServices = createAction('RESET_SERVICES')();
export const showServicesInformer = createAction('SHOW_SERVICES_INFORMER')();
export const setNotSignedSupAgreement = createAction('SERVICES_SET_NOT_SIGNED_SUP_AGREEMENT')();
