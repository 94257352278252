import React, { useMemo } from 'react';
import BottomSheet from '../../../../common/BottomSheet';
import HtmlText from '../../../../common/utils/HtmlText';
import { moneyFormat } from '../../../../../utils/format';

const ListItem = ({ conditionIconName, conditionText }) => (
  <div key={conditionIconName} className="list-item-wrapper">
    <img
      src={`/services/service-benefits/${conditionIconName}.svg`}
      alt={conditionIconName}
      className="list-item-icon"
    />
    <HtmlText className="item-description" tag="span">{conditionText}</HtmlText>
  </div>
);

const ServiceInfoModal = ({ onClose, active, optionBenefit, id, activationConditionList, activationRestrictionText }) => {
  const titleText = useMemo(() => {
    if ([234, 240].includes(id)) {
      return 'Как работает услуга';
    }
    return 'Как работает опция';
  }, [id]);

  return (
    <BottomSheet
      open={active}
      onDismiss={onClose}
    >
      {id === 233 ? (
        <div className="option-benefits-wrapper">
          <div className="net-benefit-wrapper">
            <span className="sum">{moneyFormat(optionBenefit.netBenefit)}</span>
            <p className="description">Экономия по кредиту с учётом<br />переплаты и стоимости опции*</p>
          </div>
          <div className="benefits-info-wrapper">
            <div className="overpayment-on-loan-wrapper">
              <div className="sum-wrapper">
                <span className="title">Переплата по кредиту</span>
                <span className="sum">{moneyFormat(optionBenefit.overpaymentWithOption)}</span>
              </div>
              <div className="sum-wrapper-without-benefit">
                <span className="title">Без опции</span>
                <span className="sum">{moneyFormat(optionBenefit.overpaymentWithoutOption)}</span>
              </div>
            </div>
            <div className="monthly-payment-wrapper">
              <div className="sum-wrapper">
                <span className="title">Ежемесячный платёж</span>
                <span className="sum">{moneyFormat(optionBenefit.installmentAmountWithOption)}</span>
              </div>
              <div className="sum-wrapper-without-benefit">
                <span className="title">Без опции</span>
                <span className="sum">{moneyFormat(optionBenefit.installmentAmountWithoutOption)}</span>
              </div>
            </div>
            <div className="option-price-wrapper">
              <div className="sum-wrapper">
                <span className="title">Стоимость опции</span>
                <span className="sum">{moneyFormat(optionBenefit.tariffAmount)}</span>
              </div>
            </div>
          </div>

          <p className="option-benefits-hint">
            <span>*</span>Информация рассчитана по первоначальному графику
          </p>
        </div>
      ) : (
        <>
          <div className="service-info-wrapper">
            <h1 className="service-info-title">{titleText}</h1>
            <div className="service-info-content-wrapper">
              {activationConditionList?.map((list) => (
                <div key={list.conditionTypeCode} className="service-info-list">
                  <h2 className="list-title">{list.conditionTypeName}</h2>
                  {list.conditionTypeName && list.conditionList.filter((item) => item.conditionIconName && item.conditionText)
                    .map((item) => (
                      <ListItem key={item.conditionIconName} {...item} />
                    ))}
                </div>
              ))}
            </div>
          </div>
          {activationRestrictionText && (
            <div className="activation-restrictions-hint-wrapper">
              <span className="title">Ограничения по активации</span>
              <HtmlText className="activation-restrictions" tag="p">{activationRestrictionText}</HtmlText>
            </div>
          )}
        </>
      )}
    </BottomSheet>
  );
};

export default ServiceInfoModal;
