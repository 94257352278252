import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import BottomSheet from '../../../../common/BottomSheet';
import DomHolder from '../../../../common/utils/DomHolder';
import AlertBlock from '../../../../common/AlertBlock';
import { Button } from '../../../../../ui-kit';
import { useAsyncStatus, useFixedFooter, useUser } from '../../../../common/hooks';
import { moneyFormat, moneyFormatWithTrailingZeroStripIfInteger, percentFormat } from '../../../../../utils/format';
import postToNativeApp from '../../../../../utils/postToNativeApp';
import HtmlText from '../../../../common/utils/HtmlText';
import { finProtectRefuseAsync } from '../../../../../actions/services';

function ServiceRefuseModalContent({ serviceRefuseConfig, creditId, setGoToChatLoading, onClose, goToServiceClick }) {
  const {
    id, name, isCancelAvailable, isAccountClosed, prolongatedFinishDate, cancelRefundAmount, discountRate,
    cancelAvailableMessage, packageName, optionBenefit = {}, key,
  } = serviceRefuseConfig;

  const user = useUser();
  const dispatch = useDispatch();
  const serviceRefuseStatus = useAsyncStatus((state) => state.services.finProtectRefuseStatus);
  const pageLoading = (serviceRefuseStatus.error?.status === 403 && serviceRefuseStatus.error?.error_code === 3) ||
    serviceRefuseStatus.loading || serviceRefuseStatus.success;

  const descriptionText = useMemo(() => {
    if (!isCancelAvailable) {
      if (!cancelAvailableMessage) {
        if (id === 234 || id === 240) {
          return 'Отказ от услуги в приложении недоступен. Если у вас есть вопросы, пожалуйста, обращайтесь в чат';
        }
        return 'Отказ от опции в приложении недоступен. Если у вас есть вопросы, пожалуйста, обращайтесь в чат';
      }
      return cancelAvailableMessage;
    }
    return 'Вы уверены, что хотите отказаться? Отменить изменения будет невозможно';
  }, [isCancelAvailable, cancelAvailableMessage, id]);

  const titleText = useMemo(() => {
    if (isCancelAvailable) {
      if (!prolongatedFinishDate) {
        if (id === 234 || id === 240) {
          return `Отказ от услуги<br />«${name}»`;
        }
        return `Отказ от опции<br />«${name}»`;
      }
      if (prolongatedFinishDate) {
        if (id === 233 || id === 251) {
          return `Отказ от продления опции<br />«${name}»`;
        }
        return `Отказ от продления услуги<br />«${name}»`;
      }
    }
    return 'Отказ недоступен';
  }, [isCancelAvailable, name, id]);

  const buttonText = useMemo(() => {
    if (!isCancelAvailable) {
      if (cancelAvailableMessage) {
        return 'Понятно';
      }
      return 'Перейти в чат';
    }
    return 'Не отказываться';
  }, [isCancelAvailable, cancelAvailableMessage]);

  const alertText = useMemo(() => {
    if (!cancelRefundAmount && cancelRefundAmount !== 0) return '';
    const formatSum = moneyFormat(cancelRefundAmount);
    const textStart = `Сумма к возврату составит ${formatSum}.`;

    switch (id) {
      case 251:
        return `${textStart} Процентная ставка при отказе может увеличиться согласно <a href="https://www.rosbank-auto.ru/obshie-usloviya-kreditovaniya/" target ="_blank">условиям договора</a>`;
      case 234:
        if (discountRate >= 0) return `${textStart} Погашение кредита будет возможно только в дату платежа по графику${discountRate > 0 ? `. Процентная ставка при отказе может увеличиться на&nbsp;${percentFormat(discountRate)}` : ''}`;
        return '';
      default:
        if (discountRate >= 0) return discountRate === 0 ? `${textStart} Текущий график платежей при отказе не&nbsp;изменится` : `${textStart} Процентная ставка при отказе может увеличиться на&nbsp;${percentFormat(discountRate)}`;
        return '';
    }
  }, [id, cancelRefundAmount, discountRate]);

  const onButtonClick = useCallback(() => {
    if (!cancelAvailableMessage && !isCancelAvailable) {
      postToNativeApp({
        type: 'goToChat',
      });
      setGoToChatLoading(true);
    }
    onClose();
  }, [cancelAvailableMessage]);

  const onRefuseServiceClick = useCallback(() => {
    const config = {
      contractNumber: creditId,
      operatorId: user.operator_id,
      isAccountClosed,
      serviceId: id,
      serviceName: name,
      cancelingServiceName: name,
      ...(packageName && { packageName }),
    };

    dispatch(finProtectRefuseAsync(config));
  }, [creditId, user.operator_id, serviceRefuseConfig]);

  const footerRef = useRef();
  useFixedFooter(footerRef, 'service-refuse-modal', [id, serviceRefuseStatus.error]);

  return (
    <div className="service-refuse-modal">
      <div className={classnames('service-refuse-content', { loading: pageLoading })}>
        <HtmlText className="title" tag="h1">{titleText}</HtmlText>
        <p className="refuse-description">{descriptionText}</p>

        {id === 233 && optionBenefit.netBenefit > 0 && (
          <div className="loan-benefit-wrapper">
            <div className="benefit-amount">
              <span className="amount">{moneyFormatWithTrailingZeroStripIfInteger(optionBenefit.netBenefit)}</span>
              <span className="description">Экономия по кредиту</span>
            </div>
            <button className="benefit-info-button" onClick={() => goToServiceClick(key)}>
              Подробнее
            </button>
          </div>
        )}

        {!!isCancelAvailable && !!alertText && <AlertBlock text={alertText} status="warning" margin="0 0 12px" />}
      </div>

      <div className="service-refuse-footer" ref={footerRef}>
        {serviceRefuseStatus.error && serviceRefuseStatus.error.status !== 403 && serviceRefuseStatus.error_code !== 3 && (
          <AlertBlock text="Не удалось загрузить данные.<br />Попробуйте повторить попытку" status="fatal-error" margin="24px 0 36px" />
        )}

        <Button
          className="footer-button"
          design="grey"
          size="m"
          disabled={serviceRefuseStatus.loading}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>

        {!!isCancelAvailable && (
          <Button
            className="footer-button without-bg"
            design="grey"
            size="m"
            loading={pageLoading}
            onClick={onRefuseServiceClick}
          >
            Отказаться
          </Button>
        )}
      </div>
    </div>
  );
}

function ServiceRefuseModal({ serviceRefuseConfig, creditId, onClose, goToServiceClick, setGoToChatLoading }) {
  return (
    <BottomSheet
      open={!!serviceRefuseConfig}
      onDismiss={onClose}
    >
      <DomHolder>
        {!!serviceRefuseConfig && (
          <ServiceRefuseModalContent
            serviceRefuseConfig={serviceRefuseConfig}
            creditId={creditId}
            setGoToChatLoading={setGoToChatLoading}
            goToServiceClick={goToServiceClick}
            onClose={onClose}
          />
        )}
      </DomHolder>
    </BottomSheet>
  );
}

export default ServiceRefuseModal;
