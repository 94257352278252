import { useEffect, useLayoutEffect } from 'react';

function getAbsoluteHeight(el) {
  if (!el) return 0;

  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

  return Math.ceil(el.clientHeight + margin);
}

function setCssStyles(el, styles) {
  if (el?.style) {
    Object.entries(styles).forEach(([key, value]) => {
      el.style[key] = value;
    });
  }
}

const defaultStyles = {
  width: 'calc(100% - 32px)', // 16px * 2 - horizontal paddings
  position: 'fixed',
  bottom: 0,
  zIndex: 2,
};

/**
 * @param {Object} ref
 * @param {string} pageContentCls //page element class with paddings
 * @param {array} dependencies
 * @param {Object} styles
 */
function useFixedFooter(ref, pageContentCls, dependencies = [], styles = defaultStyles) {
  useLayoutEffect(() => {
    const pageContentEl = document.querySelector(`.${pageContentCls}`);
    if (pageContentEl?.style) pageContentEl.style.paddingBottom = '';
    if (ref.current?.style) ref.current.style.paddingBottom = '';

    setCssStyles(ref.current, styles);

    const footerHeight = getAbsoluteHeight(ref.current);
    const pageContentPaddingBottom = !pageContentEl ? 24 : parseFloat(window.getComputedStyle(pageContentEl)?.paddingBottom);
    const totalPaddingBottomHeight = footerHeight + pageContentPaddingBottom;

    if (ref.current?.style) ref.current.style.paddingBottom = `${pageContentPaddingBottom}px`;
    if (pageContentEl?.style) pageContentEl.style.paddingBottom = `${totalPaddingBottomHeight}px`;
  }, dependencies);

  useEffect(() => () => {
    const pageContentEl = document.querySelector(`.${pageContentCls}`);
    if (pageContentEl?.style) pageContentEl.style.paddingBottom = '';
    if (ref.current?.style) ref.current.style.paddingBottom = '';
  }, []);
}

export default useFixedFooter;
