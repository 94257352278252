import { useMemo } from 'react';
import { formatDate } from '../../../../../utils/format';

export const useStatus = (service) => useMemo(() => {
  const {
    type, id, statusCode, restActivationCount, prolongatedFinishDate,
    finishDate, currentServiceFinishDate, isActivationAvailable, beginDate,
  } = service || {};

  if (type === 'registred') {
    if (statusCode === 'FUNCTIONAL' && [233, 234, 240, 251].includes(id) && (prolongatedFinishDate || finishDate)) {
      return { type: 'success', label: `Действует до ${formatDate(prolongatedFinishDate || finishDate)}` };
    }
    if (statusCode === 'PAID' && beginDate) {
      return { type: 'info', label: `Активируется ${formatDate(beginDate)}` };
    }
    if ((statusCode === 'CANCELED' || statusCode === 'FINISHED' || statusCode === 'COMPLETED') && finishDate) {
      return { type: 'canceled', label: `${statusCode === 'CANCELED' ? 'Отменена' : 'Завершена'}  ${formatDate(finishDate)}` };
    }
    if (statusCode === 'FUNCTIONAL' && (restActivationCount > 0 || (!isActivationAvailable && restActivationCount === 0 && ![233, 234, 240, 251].includes(id)))) {
      return isActivationAvailable && restActivationCount > 0 ? { type: 'pending', label: 'Нужно активировать' } : { type: 'blocked', label: 'Активация недоступна' };
    }
  }

  if (type === 'prolongation' && currentServiceFinishDate) {
    return { type: 'warning', label: `Продлите до ${formatDate(currentServiceFinishDate)}` };
  }

  return {};
}, [service]);
