import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import classnames from 'classnames';
import * as yup from 'yup';
import {
  getCarInfoAsync,
  getInsurancesProductsAsync,
  getUploadDocumentTypesAsync,
  sendCarInfoAsync
} from '../../../../actions/insurances-products';
import { Button, Checkbox, FormControl, LabelValue } from '../../../../ui-kit';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import AlertBlock from '../../../common/AlertBlock';
import { useAsyncStatus } from '../../../common/hooks';
import DomHolder from '../../../common/utils/DomHolder';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import Hint from '../../../../ui-kit/hint/Hint';
import InputField from '../../../common/forms/fields/InputField';
import FileInputField from '../../../common/forms/fields/FileInputField';
import CollapsibleElement from '../../../common/CollapsibleElement';
import { customFileValidate } from '../../../../utils/customFileValidate';
import useValidate from '../../../common/hooks/useValidate';

const validationSchema = yup.object().shape({
  customerComment: yup.string(),
});

const CarInfoPage = ({ match, history }) => {
  const { creditId, insuranceId } = match.params;
  const dispatch = useDispatch();
  const carInfoById = useSelector((state) => state.insurancesProducts.carInfoById);
  const carInfo = carInfoById[creditId] ?? {};
  const [isCarInfoActual, setIsCarInfoActual] = useState(true);
  const [mainHint, setMainHint] = useState({});
  const getCarInfoFetchStatus = useAsyncStatus((state) => state.insurancesProducts.getCarInfoFetchStatus);
  const uploadDocumentTypesFetchStatus = useAsyncStatus((state) => state.insurancesProducts.uploadDocumentTypesFetchStatus);
  const sendCarInfoFetchStatus = useAsyncStatus((state) => state.insurancesProducts.sendCarInfoFetchStatus);

  const getInsurancesProductsFetchStatus = useSelector((state) => state.insurancesProducts.getInsurancesProductsFetchStatus);
  const insurancesProductsById = useSelector((state) => state.insurancesProducts.byId);
  const uploadDocumentTypes = useSelector((state) => state.insurancesProducts.uploadDocumentTypes);
  const insurancesProducts = insurancesProductsById[creditId] ?? [];

  const initialValues = useMemo(() => {
    const fileFields = uploadDocumentTypes ? Object.keys(uploadDocumentTypes).reduce((acc, key) => { acc[key] = []; return acc; }, {}) : {};

    return {
      ...fileFields,
      customerComment: '',
    };
  }, [uploadDocumentTypes]);
  const currentPage = useMemo(() => match.url.split('/')[4], [match.url]);
  const insuranceProduct = useMemo(() => (
    insurancesProducts.find((item) => item.insuranceId === +insuranceId)), [insurancesProducts, insuranceId]);

  const handleSubmit = useCallback((values) => {
    const filteredValues = { ...values };
    const documentsData = Object.values(values).filter((value) => !!value?.[0]?.fileTypeCode).flat();

    Object.keys(uploadDocumentTypes).forEach((fileTypeCode) => delete filteredValues[fileTypeCode]);

    dispatch(sendCarInfoAsync({
      requestChannel: 'RBMOBILE',
      contractNumber: creditId,
      documentsData,
      pledgeNumber: insuranceProduct?.pledgeNumber,
      ...(filteredValues.customerComment && { customerComment: filteredValues.customerComment }),
    }));
  }, [uploadDocumentTypes, insuranceProduct]);

  const customValidate = useCallback((values) => customFileValidate(values, uploadDocumentTypes, setMainHint), [uploadDocumentTypes]);
  const validate = useValidate(validationSchema, customValidate);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'goBack' } });
    if (!Object.keys(carInfo).length && insuranceProduct?.pledgeNumber) {
      dispatch(getCarInfoAsync({ contractNumber: creditId, pledgeNumber: insuranceProduct?.pledgeNumber }));
    }
  }, []);

  useEffect(() => {
    if (!getInsurancesProductsFetchStatus.success) {
      dispatch(getInsurancesProductsAsync({ contractNumber: creditId }));
    }
  }, []);

  useEffect(() => {
    if (insuranceProduct?.pledgeNumber) {
      dispatch(getCarInfoAsync({ contractNumber: creditId, pledgeNumber: insuranceProduct?.pledgeNumber }));
    }
  }, [insuranceProduct?.pledgeNumber]);

  useEffect(() => {
    if ((getCarInfoFetchStatus.success || Object.keys(carInfo).length) && currentPage === 'car-info-change') {
      dispatch(getUploadDocumentTypesAsync('PTS'));
    }
  }, [getCarInfoFetchStatus.success]);

  useEffect(() => {
    if (sendCarInfoFetchStatus.success) {
      history.push({ pathname: `/credits/${creditId}/insurances-products/application-accepted`, state: { pageType: 'carInfo' } });
    }
  }, [sendCarInfoFetchStatus.success]);

  const goToSendPolicyPage = useCallback(() => {
    history.push({
      pathname: `/credits/${creditId}/insurances-products/send-policy/${insuranceProduct.insuranceId}`,
      state: { isCarInfoActual },
    });
  }, [creditId, insuranceProduct, isCarInfoActual]);

  return (
    <LoaderOrChildren
      loading={getCarInfoFetchStatus.loading || (currentPage === 'car-info-change' ? !uploadDocumentTypesFetchStatus.success : (!getCarInfoFetchStatus.success && !Object.keys(carInfo).length))}
    >
      <div className="page-wrapper car-info-page">
        <div className="car-info-page-container">
          {(insuranceId === '1' || insuranceId === '1001') && currentPage !== 'car-info-change' && <h2 className="title">Объект страхования</h2>}
          <LabelValue label="Данные об автомобиле" value={carInfo.vehicleInfo} />
          <LabelValue label="VIN-номер автомобиля" value={carInfo.vehicleIdNumber} />
          {currentPage === 'car-info-change' && (
          <Hint className="car-info-hint" type="info">Если данные об&nbsp;автомобиле указаны неверно, предоставьте фото ПТС/ЭПТС, чтобы мы
            внесли изменения в&nbsp;систему
          </Hint>
          )}

          {currentPage === 'car-info-change' && (
            <Formik initialValues={initialValues} validate={(insuranceId === '1' || insuranceId === '1001') ? validate : customValidate} onSubmit={handleSubmit}>
              {({ isValid, submitCount, touched, values }) => {
                // const buttonEnabled = useMemo(() => (
                //   Object.keys(uploadDocumentTypes).every((key) => values[key].length > 0)
                // ), [values, uploadDocumentTypes]);
                const allFieldsRequiredError = useMemo(() => {
                  const error = { type: 'error', label: '' };
                  if (!isValid && submitCount) {
                    error.label = 'Чтобы продолжить, необходимо прикрепить все требуемые файлы';
                  }
                  return error;
                }, [isValid, insuranceId, submitCount]);

                return (
                  <Form className="car-info-form">
                    <div className="form-container">

                      {Object.entries(uploadDocumentTypes)
                        .map(([fieldName, { label, maxFiles }]) => (
                          <FormControl
                            key={fieldName}
                            design="v2"
                            label={label}
                            className={classnames('form-control', { _error: !!touched[fieldName] && values[fieldName]?.length < 1 })}
                          >
                            <FileInputField
                              name={fieldName}
                              maxFiles={maxFiles}
                              placeholder="Прикрепить файл"
                              design="v2"
                              multiple={false}
                            />
                          </FormControl>
                        ))}

                      <p className="description">
                        Вы можете прикрепить файлы в&nbsp;форматах jpeg, jpg, png, pdf
                        размером до&nbsp;5&nbsp;МБ. Общий размер всех файлов не&nbsp;должен превышать 20&nbsp;МБ
                      </p>

                      <FormControl className="form-control comment">
                        <InputField
                          name="customerComment"
                          label="Комментарий"
                          design="v2"
                          textarea
                        />
                      </FormControl>

                      <CollapsibleElement active={!!mainHint.label || !!allFieldsRequiredError.label}>
                        <DomHolder>
                          {(!!mainHint.label || !!allFieldsRequiredError.label) && (
                            <AlertBlock
                              text={mainHint.label || allFieldsRequiredError.label}
                              status={mainHint.type || allFieldsRequiredError.type}
                            />
                          )}
                        </DomHolder>
                      </CollapsibleElement>
                    </div>

                    <Button
                      design="red"
                      className="submit-button"
                      size="l"
                      loading={sendCarInfoFetchStatus.loading || !!sendCarInfoFetchStatus.error}
                      // disabled={!buttonEnabled}
                    >
                      Отправить
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          )}

          {(insuranceId === '1' || insuranceId === '1001') && currentPage === 'car-info' && (
            <>
              <FormControl compact>
                <Checkbox
                  checked={!isCarInfoActual}
                  onChange={() => setIsCarInfoActual((prev) => !prev)}
                  design="rounded"
                  labelAlign="left"
                  className="car-info-checkbox"
                >
                  Данные об автомобиле не актуальны
                </Checkbox>
              </FormControl>
              {!isCarInfoActual && (
                <Hint type="info" className="car-info-hint actual">Прикрепите фото ПТС/ЭПТС на&nbsp;следующем экране, чтобы мы&nbsp;внесли
                  изменения в&nbsp;систему
                </Hint>
              )}
            </>
          )}
        </div>

        {currentPage === 'car-info' && (
          <Button
            className="submit-button"
            design="red"
            type="button"
            loading={uploadDocumentTypesFetchStatus.loading}
            size="l"
            onClick={goToSendPolicyPage}
          >
            Продолжить
          </Button>
        )}
      </div>
    </LoaderOrChildren>
  );
};

export default CarInfoPage;
