import accept from 'attr-accept';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import CollapsibleErrorHint from '../../components/common/CollapsibleErrorHint';
import { formatFileSize } from '../../utils/format';
import { useFormControl } from '../form-control/useFormControl';
import { transition } from '../styles/mixins';
import FileInputItem from './FileInputItem';
import AttachIcon from './assets/attach.svg';
import RemoveIcon from './assets/remove.svg';
import RemoveIconV2 from './assets/remove-v2.svg';
import FileIcon from './assets/file.svg';
import JpgIcon from './assets/jpg.svg';
import PdfIcon from './assets/pdf.svg';

const UiFileInput = styled.div`
  //border: 1px dashed #979797;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23979797' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;

  &.state-invalid {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E60028' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  }

  &.v2 {
    background: none;
    padding-top: 8px;

    label {
      height: 32px;
      border-radius: 6px;
      background-color: #E5E7EA;
      padding: 8px 16px;
      width: max-content;

      .placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000C1A;
        text-transform: none;
        margin-left: 0;
      }
    }

    .file-input-item {
      margin: 0;
      .file-input-item-content {
        padding-bottom: 24px;
      }
    }

    .attached-file {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .description-wrap {
        display: flex;
        align-items: center;

        .description-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 12px;

          .file-name {
            font-size: 16px;
            line-height: 20px;
            color: #000C1A;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .description {
            all: unset;
            font-size: 14px;
            line-height: 16px;
            color: #647081;
            margin-top: 4px;
          }
        }
      }

      .attached-file-remove {
        border: none;
        background-color: transparent;
      }
    }

    .collapsible-error-hint {
      text-align: left;

      .collapsible-error-hint-content {
        font-size: 14px;
        line-height: 16px;
        color: #E40038;
        padding: 12px 0;
      }
    }
  }

  &._disabled {
    label {
      height: 0;
      padding: 0;

      .placeholder {
        opacity: 0;
      }
    }
    .file-input-item {
      &:last-child {
        .file-input-item-content {
          &:before {
            opacity: 0;
          }
        }
      }
    }
  }

  .file-input-item {
    position: relative;
    overflow: hidden;
    margin: 0 16px;
    text-align: center;
    transition: height ${transition.slow};
    //border-bottom: 1px solid #DADADA; // lil jumping at the end of animation detected, so better absolute line
  }

  &.v1 {
    .file-input-item-content {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #DADADA;
        transition: opacity ${transition.slow};
      }
    }

    .attached-file {
      position: relative;
      min-height: 48px;
      padding: 15px 30px 13px 0;
      box-sizing: border-box;
      text-align: left;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
    }

    .attached-file-name {
      display: block;
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
      color: #000000;
      text-transform: uppercase;
    }

    .attached-file-error {
      display: block;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
      color: #E60028;
    }

    .attached-file-remove {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background: none;
      outline: none;
      border: none;
      box-shadow: none;
      -webkit-appearance: none;
      padding: 16px 16px 20px;
      margin-right: -16px;

      svg {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  label {
    cursor: pointer;
    width: 100%;
    height: 72px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height ${transition.slow};
    will-change: transform;

    .placeholder {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: #979797;
      transition: opacity ${transition.slow};
    }

    input {
      display: none;
    }
  }
`;

const UiFileHint = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 13px;
  line-height: 16px;
  color: #979797;
`;

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const getIconByFileType = (type) => {
  switch (type) {
    case 'pdf':
      return <PdfIcon />;
    case 'jpg':
    case 'jpeg':
      return <JpgIcon />;
    case 'png':
    default:
      return <FileIcon />;
  }
};

const FileInput = forwardRef((props, ref) => {
  const {
    multiple,
    className,
    placeholder,
    state,
    value,
    acceptTypes,
    disabled,
    maxSize,
    maxFiles,
    groupSize,
    maxGroupSize,
    withHint,
    formErrorShown,
    formErrorMessage,
    onChange,
    design = 'v1',
    ...attributes
  } = props;
  const cls = classnames('ui-file-input', `state-${state}`, className, design, { _disabled: value?.length >= maxFiles || disabled });
  const fcProps = useFormControl(props);
  const [uid, setUid] = useState(0);
  const [lastUploaded, setLastUploaded] = useState([]);
  const fileWithError = useMemo(() => lastUploaded.find(({ fileError }) => fileError), [lastUploaded]);
  const [hasPermissions, setHasPermissions] = useState(true);

  const handleChange = useCallback((e) => {
    const { files } = e.target;
    if (files.length) {
      setUid((prevUid) => prevUid + 1);
      setLastUploaded([]);
      let nameDuplication = 0;

      const attachPromisesArr = Array.from(files)
        .filter(({ name, size }) => (
          value && !!size && !value.find(({ fileName, fileExtension, fileSize }) => {
            if (`${fileName}.${fileExtension}`.replace(/ copy+\d/gm, '') === name) nameDuplication++;
            return `${fileName}.${fileExtension}`.replace(/ copy+\d/gm, '') === name && fileSize === size;
          })))
        .map((file) => {
          const { name, type: mimeType, size: fileSize } = file;
          const isFileDuplication = value.some(({ fileExtension, fileName }) => name === `${fileName}.${fileExtension}`);

          const fileName = (name.substr(0, name.lastIndexOf('.')) || name) + (nameDuplication && isFileDuplication ? ` copy${nameDuplication}` : '');
          const fileExtension = name.split('.').pop();

          if (!accept(file, acceptTypes) && fileSize > maxSize) {
            const fileError = design === 'v2' ? 'Прикрепите файл в формате jpeg, jpg, png, pdf размером до 5 МБ' :
              'Неверный формат файла.';
            return Promise.resolve({ fileName, fileExtension, fileSize, fileError });
          }

          if (!accept(file, acceptTypes)) {
            const fileError = design === 'v2' ? 'Прикрепите файл в формате jpeg, jpg, png, pdf' :
              'Неверный формат файла.';
            return Promise.resolve({ fileName, fileExtension, fileSize, fileError });
          }

          if (fileSize > maxSize) {
            const fileError = design === 'v2' ? 'Прикрепите файл размером до 5 МБ' :
              `Превышен допустимый размер файла (${formatFileSize(fileSize)} / ${formatFileSize(maxSize)})`;
            return Promise.resolve({ fileName, fileExtension, fileSize, fileError });
          }

          return toBase64(file)
            .then((fileBody) => ({ fileTypeCode: props.name, mimeType, fileExtension, fileName, fileBody, fileSize }))
            .catch(() => {
              const fileError = 'Ошибка преобразования файла (readAsDataURL failed)';
              return { fileTypeCode: props.name, fileName, fileExtension, fileError, fileSize };
            });
        });

      Promise.all(attachPromisesArr)
        .then((attachedFiles) => {
          setLastUploaded((prev) => [...prev, ...(attachedFiles.filter(({ fileError }) => !!fileError))]);
          return onChange([...value, ...(attachedFiles.filter(({ fileError }) => !fileError))]);
        })
        .catch(() => {});
    }
  }, [maxSize, value, design]);

  const handleRemoveClick = useCallback((e) => {
    const { currentTarget: { dataset: { removeFileName } } } = e;
    setUid((prevUid) => prevUid + 1);
    onChange(value?.filter(({ fileName, fileExtension }) => (fileName + fileExtension) !== removeFileName));
  }, [value]);

  async function checkPermissions() {
    try {
      const cameraPermission = await window.navigator.permissions.query({ name: 'camera' });
      const storagePermission = await window.navigator.permissions.query({ name: 'persistent-storage' });

      if (storagePermission.state === 'denied' && cameraPermission.state === 'denied') {
        setHasPermissions(false);
      } else {
        setHasPermissions(true);
      }
    } catch (err) {
      console.error('You don\'t have permissions:', err);
    }
  }

  return (
    <>
      <UiFileInput className={cls}>
        <TransitionGroup>
          {value && value.map(({ fileName, fileExtension, fileError, fileSize }) => {
            if (design === 'v2' && fileError) {
              return null;
            }

            return (
              <FileInputItem key={fileName + fileExtension}>
                {fileName && (design === 'v1' || !fileError) && (
                  <div className={classnames('attached-file', { _error: !!fileError })}>
                    {design === 'v2' ? (
                      <div className="description-wrap">
                        {getIconByFileType(fileExtension)}
                        <div className="description-container">
                          <span className="file-name">{fileName}</span>
                          <span
                            className="description"
                            dangerouslySetInnerHTML={{ __html: `${fileExtension}, ${formatFileSize(fileSize)}` }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="attached-file-name">
                          {`${fileName}.${fileExtension}`}
                        </span>
                        {fileError && <span className="attached-file-error" dangerouslySetInnerHTML={{ __html: fileError }} />}
                      </>
                    )}
                    {((design === 'v2' && value?.length <= maxFiles) || design === 'v1') && (
                      <button
                        type="button"
                        className="attached-file-remove"
                        data-remove-file-name={fileName + fileExtension}
                        onClick={handleRemoveClick}
                      >
                        {design === 'v2' ? <RemoveIconV2 /> : <RemoveIcon />}
                      </button>
                    )}
                  </div>
                )}
              </FileInputItem>
            );
          })}
        </TransitionGroup>
        <label>
          {design === 'v1' && <AttachIcon />}
          <span className="placeholder">{placeholder}</span>
          <input
            key={uid}
            type="file"
            accept={acceptTypes}
            multiple={multiple}
            ref={ref}
            disabled={value?.length >= maxFiles || disabled}
            {...attributes}
            {...fcProps}
            onChange={handleChange}
            onClick={checkPermissions}
          />
        </label>

        {design === 'v2' && (
          <CollapsibleErrorHint
            active={!!fileWithError || !hasPermissions}
            message={!hasPermissions ? 'В настройках приложения разрешите доступ к камере и галерее' : fileWithError?.fileError}
          />
        )}
      </UiFileInput>
      {formErrorShown && <CollapsibleErrorHint active={formErrorShown} message={formErrorMessage} />}
      {withHint && (
        <UiFileHint className="file-hint" dangerouslySetInnerHTML={{ __html: `Максимальный размер файла ${formatFileSize(maxSize)}. Максимальный размер всех прикрепленных файлов ${formatFileSize(maxGroupSize)}. Пожалуйста, проверьте, чтобы формат загружаемых вами файлов соответствовал разрешенным расширениям: pdf, png, jpg, jpeg` }} />
      )}
    </>
  );
});

FileInput.displayName = 'FileInput';

FileInput.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.array,
  acceptTypes: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  groupSize: PropTypes.number,
  maxGroupSize: PropTypes.number,
  withHint: PropTypes.bool,
  formErrorShown: PropTypes.bool,
  formErrorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  state: PropTypes.oneOf(['default', 'valid', 'invalid']),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

FileInput.defaultProps = {
  multiple: true,
  // eslint-disable-next-line max-len
  // accept: '.doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpg, .jpeg, .zip, .rar, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  acceptTypes: '.pdf,.png,.jpg,.jpeg,application/pdf,image/png',
  maxSize: 5000000,
  maxGroupSize: 20000000,
  withHint: false,
  placeholder: 'Приложите документы',
  state: 'default',
};

export default FileInput;
