import PropTypes from 'prop-types';
import React from 'react';
import HtmlText from '../../../../common/utils/HtmlText';

const AdditionalInfoItem = ({ addInfoIconName, addInfoText }) => (
  <div key={addInfoIconName} className="service-benefit-wrapper">
    <div className="fixed-width-container">
      <img
        src={`/services/service-benefits/${addInfoIconName}.svg`}
        alt={addInfoIconName}
        className="benefit-icon"
      />
    </div>
    <HtmlText className="benefit-description" tag="span">{addInfoText}</HtmlText>
  </div>
);

function AdditionalInfoList({ serviceId, additionalInfo }) {
  if (serviceId && +serviceId !== 240) return null;
  const filteredInfo = additionalInfo?.filter((info) => info.addInfoIconName && info.addInfoText);

  return (!!filteredInfo?.length && filteredInfo.map((info) => (
    <AdditionalInfoItem key={info.addInfoIconName} {...info} />
  )));
}

AdditionalInfoList.propTypes = {
  serviceId: PropTypes.number,
  additionalInfo: PropTypes.array.isRequired,
};

export default AdditionalInfoList;
