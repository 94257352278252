import moment from 'moment';
import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { IMaskMixin } from 'react-imask';
import styled from 'styled-components';
import BottomSheet from '../../components/common/BottomSheet';
import useStateWithRef from '../../components/common/hooks/useStateWithRef';
import RmcDatePicker from './RmcDatePicker';
import { Button, Input } from '../index';
import CalendarIcon from './assets/calendar.svg';

const StyledModal = styled(BottomSheet)`
  div[data-rsbs-footer] {
    box-shadow: none;
  }
`;

const now = new Date();
const maxDate = new Date(2040, 11, 31, 23, 59, 59);
const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

const formatMonth = (index) => months[index];

const SuffixComponent = ({ withMask, onClick }) => {
  if (withMask) {
    return (
      <button type="button" className="suffix-button" onClick={onClick}>
        <CalendarIcon className="ui-datepicker-icon" />
      </button>
    );
  }

  return (
    <CalendarIcon className="ui-datepicker-icon" />
  );
};

function useCombinedRefs(...refs) {
  return (target) => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(target);
      } else {
        ref.current = target;
      }
    });
  };
}

const MaskedInput = IMaskMixin(({ inputRef, ref, ...props }) => (
  <Input
    ref={useCombinedRefs(inputRef, ref)}
    {...props}
  />
));

const WheelDatePicker = memo(
  forwardRef(({ onChange, onFocus, onBlur, datePickerProps, withMask, setFocused, ...props }, ref) => {
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [date, setDate, dateRef] = useStateWithRef(now);
    const contentRef = useRef();
    const maskRef = useRef();

    const onCloseModal = useCallback(() => {
      setIsActiveModal(false);

      if (onBlur) {
        onBlur({ target: { name: props.name } });
      }
    }, [onBlur]);

    const handleDateChange = useCallback((date) => {
      setDate(date);
    }, []);

    const handleFocus = useCallback((e) => {
      if (!withMask) {
        e.nativeEvent.target.blur();
        setIsActiveModal(true);
      }

      if (setFocused && props.name) {
        setFocused((prev) => ({
          ...prev,
          [props.name]: true,
        }));
      }

      if (onFocus) {
        onFocus(e);
      }
    }, [onFocus, withMask, setFocused, props.name]);

    const handleBlur = useCallback((e) => {
      if (setFocused && props.name) {
        setFocused((prev) => ({
          ...prev,
          [props.name]: false,
        }));
      }

      if (onBlur) {
        onBlur(e);
      }
    }, [onBlur, setFocused, props.name]);

    const handleSubmit = useCallback(() => {
      const formattedDate = moment(dateRef.current).format('DD.MM.YYYY');

      onChange(formattedDate);
      setTimeout(() => onCloseModal(), 0);
    }, [onCloseModal]);

    const handleClickOnSuffix = useCallback((e) => {
      e.stopPropagation();
      setIsActiveModal(true);
    }, []);

    useEffect(() => {
      if (maskRef.current?.updateValue) {
        maskRef.current.updateValue();
      }
    }, [props.value]);

    return (
      <>
        <MaskedInput
          mask="00.00.0000"
          suffix={<SuffixComponent withMask={withMask} onClick={handleClickOnSuffix} />}
          suffixCls={withMask ? 'suffix-calendar' : ''}
          onFocus={handleFocus}
          design="v2"
          readOnly={!withMask}
          onChange={onChange}
          onAccept={onChange}
          onBlur={withMask ? handleBlur : undefined}
          ref={ref}
          {...props}
        />
        <StyledModal
          open={isActiveModal}
          onDismiss={onCloseModal}
          expandOnContentDrag={false}
          footer={<Button design="red" type="button" onClick={handleSubmit}>Продолжить</Button>}
        >
          <div className="wheel-date-picker-modal" ref={contentRef}>
            <span className="title">Выберите дату</span>

            <RmcDatePicker
              className="ui-wheel-date-picker"
              mode="date"
              onDateChange={handleDateChange}
              defaultDate={date || now}
              formatMonth={formatMonth}
              maxDate={maxDate}
              {...datePickerProps}
            />
          </div>
        </StyledModal>
      </>
    );
  }),
);

export default WheelDatePicker;
