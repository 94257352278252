import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../../../ui-kit';
import Modal from '../../../../ui-kit/modal/Modal';
import decodeHtml from '../../../../utils/decodeHtml';
import { formatCurrency } from '../../../../utils/format';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import { useAsyncStatus } from '../../../common/hooks';
import PageTitle from '../../../common/PageTitle';
import Tabs from '../../../../ui-kit/tabs/Tabs';
import Tab from '../../../../ui-kit/tabs/Tab';
import DomHolder from '../../../common/utils/DomHolder';
import Informer from '../financial-services/Informer';
import EarlyPartialPayment from './EarlyPartialPayment';
import EarlyFullPayment from './EarlyFullPayment';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import { getFerAvailabilityAsync, resetEarlyRepayment } from '../../../../actions/early-payment';

const StyledButton = styled(Button)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: unset;
  width: 80%;
  color: #1E2025 !important;
  margin: auto;
`;

const EarlyPayment = (props) => {
  const { match: { params: { creditId } }, history } = props;
  const dispatch = useDispatch();
  const { error: getEarlyRepaySumByDateError } = useAsyncStatus((state) => state.credits.getEarlyRepaySumByDateStatus);
  const { error: perRequestError } = useAsyncStatus((state) => state.earlyPayment.getEarlyPartialRepaymentCalcStatus);
  const { error: ferRequestError } = useAsyncStatus((state) => state.earlyPayment.getEarlyFullRepaymentCalcStatus);
  const getFerAvailabilityStatus = useAsyncStatus((state) => state.earlyPayment.getFerAvailabilityStatus);
  const [isFatalError, setIsFatalError] = useState(false);
  const isLoading = useMemo(() => getFerAvailabilityStatus.state === 'initial' || getFerAvailabilityStatus.loading, [getFerAvailabilityStatus]);
  const hasBackButton = !window.sessionStorage.getItem('chapter');

  const {
    credit,
    processLoading,
    isAvailable,
    availableWithoutRequestDates,
    availableOnRequestDates,
    hasSpecialEarlyRepayment,
    isTemporarilyUnavailable,
    isRepaymentAnyday,
    earlyRepaySum,
    earlyRepayAttemptsCount,
  } = useSelector((state) => ({
    credit: state.credits.byId[creditId],
    processLoading: state.earlyPayment.ferAvailability[creditId]?.processLoading,
    isAvailable: state.earlyPayment.ferAvailability[creditId]?.isAvailable,
    availableWithoutRequestDates: state.earlyPayment.ferAvailability[creditId]?.availableWithoutRequestDates,
    availableOnRequestDates: state.earlyPayment.ferAvailability[creditId]?.availableOnRequestDates,
    hasSpecialEarlyRepayment: state.earlyPayment.ferAvailability[creditId]?.hasSpecialEarlyRepayment,
    isTemporarilyUnavailable: state.earlyPayment.ferAvailability[creditId]?.isTemporarilyUnavailable,
    isRepaymentAnyday: state.earlyPayment.ferAvailability[creditId]?.isRepaymentAnyday,
    earlyRepaySum: state.earlyPayment.ferAvailability[creditId]?.early_repay_sum,
    earlyRepayAttemptsCount: state.earlyPayment.ferAvailability[creditId]?.early_repay_attempts_count,
  }));

  const {
    additionalInfo: {
      early_payment_date: earlyPaymentDate,
    },
  } = credit;

  const [tab, setTab] = useState('');
  const [withAdditionalAlert, setWithAdditionalAlert] = useState(); // если не прошло 30 дней, то отображать информеры
  const [isShowRepeatLoadButton, setIsShowRepeatLoadButton] = useState(false);
  const [partialRepaymentCalcErrorMessage, setPartialRepaymentCalcErrorMessage] = useState('');
  const [informerLoading, setInformerLoading] = useState(true);

  const switchTab = useCallback((earlyPaymentType) => {
    setTab(earlyPaymentType);
  }, []);
  const goBack = useCallback((withClosing = true) => {
    if (withClosing) {
      postToNativeApp({ type: 'close' });
    }
    history.push(`/credits/${creditId}`);
  }, []);
  const goToLog = useCallback(() => {
    postToNativeApp({ type: 'titleChanged', payload: { chapter: 'INQUIRIES' } });
    history.push(`/credits/${creditId}/operation-log?reload=force`);
  }, []);

  const [informerProps, setInformerProps] = useState({
    active: false,
    type: 'error',
    paragraph: '',
  });

  const handleInformerClose = useCallback((withClosing) => {
    goBack(withClosing);
    setInformerProps({ active: false, paragraph: '' });
  }, []);

  useEffect(() => {
    const errorStatuses = [
      getEarlyRepaySumByDateError,
      perRequestError,
      ferRequestError,
      getFerAvailabilityStatus.error,
    ].filter((error) => error);

    if ((availableOnRequestDates?.length < 1 && availableWithoutRequestDates?.length < 1) ||
      (errorStatuses.some(({ status, error_message }) => status === 403 && error_message === 'Forbidden'))) {
      setInformerProps((prev) => ({
        ...prev,
        active: true,
        paragraph: 'Досрочное погашение временно недоступно. Пожалуйста, повторите попытку позднее',
      }));
    }
    setInformerLoading(false);
  }, [availableWithoutRequestDates, availableOnRequestDates, getEarlyRepaySumByDateError,
    perRequestError, ferRequestError, getFerAvailabilityStatus]);

  useEffect(() => {
    if (isAvailable === 0) {
      if (hasSpecialEarlyRepayment === 0 && isRepaymentAnyday === 0 && isTemporarilyUnavailable === 1) {
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: `Досрочное погашение временно недоступно. Пожалуйста, повторите попытку позднее.
          Обращаем внимание, что заявление о досрочном погашении может быть подано не менее чем за 1 (один)
          рабочий день до даты ближайшего платежа`,
        }));
      } else if (hasSpecialEarlyRepayment === 0 && isRepaymentAnyday === 1 && isTemporarilyUnavailable === 1) {
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: 'Досрочное погашение временно недоступно. Пожалуйста, повторите попытку позднее',
        }));
      } else if (hasSpecialEarlyRepayment === 0) {
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: 'По данному договору подача заявления на досрочное погашение через приложение недоступна',
        }));
      } else if (hasSpecialEarlyRepayment === 1) {
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: 'По договору есть ограничения. Для расчёта суммы и подачи заявки на досрочное погашение, обратитесь в чат',
        }));
      }
      setInformerLoading(false);
    }
  }, [isAvailable, hasSpecialEarlyRepayment, isRepaymentAnyday, isTemporarilyUnavailable]);

  // const prevSubmittedModalConfig = useMemo(() => ({
  //   opened: true,
  //   content: `На текущий месяц заявление на досрочное погашение на "${params.earlyPaymentDate}" Банком уже зарегистрировано. Для изменения суммы или отзыва данного заявления просьба обратиться в&nbsp;<a href="https://www.rusfinancebank.ru/ru/network.html">Отделение Банка</a> или позвонить по&nbsp;телефону в&nbsp;<a href="https://www.rusfinancebank.ru/ru/contacts.html">Центр обслуживания клиентов</a>`,
  //   action: 'goBack',
  //   withCloseIcon: false,
  // }), []);

  const [modalConfig, setModalConfig] = useState({ //params.prevSubmitted ? prevSubmittedModalConfig :
    opened: false,
    content: '',
    action: 'goBack',
    withCloseIcon: false,
  });

  const onModalSubmit = useCallback(() => {
    setModalConfig({ opened: false });
  }, []);

  const onModalClose = useCallback(() => {
    const { action } = modalConfig;
    dispatch(resetEarlyRepayment(creditId));
    switch (action) {
      case 'goToLog':
        goToLog();
        break;
      case 'goBack':
      default:
        goBack();
    }
  }, [modalConfig]);

  const loadGetEarlyFullRepaymentDates = () => {
    // if (Number.isNaN(isAvailable) || typeof isAvailable === 'undefined') { // проверка присутствующего значения (1 или 0)
    dispatch(getFerAvailabilityAsync(creditId));
  };

  useEffect(() => {
    loadGetEarlyFullRepaymentDates();

    dispatch(resetEarlyRepayment(creditId));
    localStorage.clear();
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (getFerAvailabilityStatus.error?.status_code === 500) {
      setIsFatalError(true);
    }
    if (getFerAvailabilityStatus.success && !getFerAvailabilityStatus.loading) {
      setIsFatalError(false);
      setIsShowRepeatLoadButton(false);
    }
  }, [getFerAvailabilityStatus, isFatalError]);

  useEffect(() => {
    if (processLoading === true || processLoading === undefined || informerProps.active) {
      return;
    }

    if (isAvailable === 1 && availableWithoutRequestDates?.length > 0 && typeof isRepaymentAnyday !== 'undefined') {
      setTab('partial');
      setWithAdditionalAlert(true);
      return;
    }

    if (isAvailable === 1 && availableWithoutRequestDates?.length) {
      setTab('full');
      setWithAdditionalAlert(true);
      return;
    }

    if (isAvailable === 1 && availableOnRequestDates?.length > 0 && !earlyRepaySum && earlyRepayAttemptsCount > 0) {
      setWithAdditionalAlert(true);
    }

    const dublicateText = `По договору есть активная заявка на досрочное погашение на сумму ${formatCurrency(earlyRepaySum)} на дату ${earlyPaymentDate}.`;
    if (isAvailable === 1 && earlyRepayAttemptsCount <= 0 && getFerAvailabilityStatus.success) {
      setTab('partial');
      setModalConfig({
        opened: true,
        title: 'Уважаемый клиент!',
        content: `${dublicateText} Лимит по запросам на изменение параметров активной заявки исчерпан. Если требуется отмена заявки, то обратитесь в Контакт-Центр`,
        action: 'goBack',
      });
      return;
    }

    if (earlyRepayAttemptsCount > 0 && earlyRepaySum !== 0 && earlyRepaySum !== null &&
      getFerAvailabilityStatus.success && isAvailable === 1) {
      setTab('partial');
      setModalConfig({
        opened: true,
        title: 'Уважаемый клиент!',
        content: `${dublicateText} Изменение параметров активной заявки в текущем процентном периоде возможно не более ${earlyRepayAttemptsCount} раз`,
        action: 'goBack',
        withSubmitButton: true,
        closeLabel: 'ЗАКРЫТЬ',
      });
      return;
    }

    if (isAvailable === 1 && (earlyRepayAttemptsCount > 0 || earlyRepayAttemptsCount === null) &&
      (earlyRepaySum === 0 || earlyRepaySum === null)) {
      setTab('partial');
    }
  }, [isLoading, getFerAvailabilityStatus.success, informerProps]);

  return (
    <div className="page-credit-list">
      <PageTitle onBackClick={() => goBack(false)} title="Досрочное погашение" />
      <LoaderOrChildren loading={isLoading}>
        {!isShowRepeatLoadButton && (
        <Tabs tab={tab} onChange={switchTab} className={classnames('tab-switch', { hasBackButton })}>
          <Tab id="partial" title="Частичное" className="early-partial-payment-tab">
            <EarlyPartialPayment
              creditId={creditId}
              setInformerProps={setInformerProps}
              setInformerLoading={setInformerLoading}
              additionalAlert={withAdditionalAlert}
              partialRepaymentCalcErrorMessage={partialRepaymentCalcErrorMessage}
              setPartialRepaymentCalcErrorMessage={setPartialRepaymentCalcErrorMessage}
            />
          </Tab>
          <Tab id="full" title="Полное" className="early-full-payment-tab">
            <EarlyFullPayment
              creditId={creditId}
              additionalAlert={withAdditionalAlert}
              setInformerProps={setInformerProps}
              setInformerLoading={setInformerLoading}
            />
          </Tab>
        </Tabs>
        )}
        <Modal
          header={modalConfig.title || 'Уважаемый клиент!'}
          active={modalConfig.opened}
          onClose={onModalClose}
        >
          <DomHolder>
            {modalConfig.opened && (
              <>
                <div className="modal-content" dangerouslySetInnerHTML={{ __html: decodeHtml(modalConfig.content) }} />
                <div className="modal-buttons">
                  {modalConfig.withSubmitButton && (
                    <Button
                      design="red"
                      size="l"
                      onClick={onModalSubmit}
                    >
                      изменить
                    </Button>
                  )}
                  <Button
                    design={modalConfig.withSubmitButton ? 'secondary' : 'red'}
                    size="l"
                    onClick={onModalClose}
                  >
                    {modalConfig.closeLabel || 'готово'}
                  </Button>
                </div>
              </>
            )}
          </DomHolder>
        </Modal>
        <Informer config={informerProps} onGotoInquiries={goToLog} loading={informerLoading} onClose={handleInformerClose} />
      </LoaderOrChildren>
    </div>
  );
};

export default EarlyPayment;
