import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showFailedToLoadData } from './app';

export const getCredits = createAsyncAction(
  'GET_CREDITS_REQUEST',
  'GET_CREDITS_SUCCESS',
  'GET_CREDITS_FAIL',
)();

export const getCreditPaymentSchedule = createAsyncAction(
  'GET_CREDIT_PAYMENT_SCHEDULE_REQUEST',
  'GET_CREDIT_PAYMENT_SCHEDULE_SUCCESS',
  'GET_CREDIT_PAYMENT_SCHEDULE_FAIL',
)();

export const getCreditRepaymentHistory = createAsyncAction(
  'GET_CREDIT_REPAYMENT_HISTORY_REQUEST',
  'GET_CREDIT_REPAYMENT_HISTORY_SUCCESS',
  'GET_CREDIT_REPAYMENT_HISTORY_FAIL',
)();

export const getCreditOperationLogAppeals = createAsyncAction(
  'GET_CREDIT_OPERATION_LOG_APPEALS_REQUEST',
  'GET_CREDIT_OPERATION_LOG_APPEALS_SUCCESS',
  'GET_CREDIT_OPERATION_LOG_APPEALS_FAIL',
)();

export const getCreditCardOperations = createAsyncAction(
  'GET_CREDIT_CARD_OPERATIONS_REQUEST',
  'GET_CREDIT_CARD_OPERATIONS_SUCCESS',
  'GET_CREDIT_CARD_OPERATIONS_FAIL',
)();

export const getCreditRequisites = createAsyncAction(
  'GET_CREDIT_REQUISITES_REQUEST',
  'GET_CREDIT_REQUISITES_SUCCESS',
  'GET_CREDIT_REQUISITES_FAIL',
)();

export const getCreditRemittanceAmount = createAsyncAction(
  'GET_CREDIT_REMITTANCE_AMOUNT_REQUEST',
  'GET_CREDIT_REMITTANCE_AMOUNT_SUCCESS',
  'GET_CREDIT_REMITTANCE_AMOUNT_FAIL',
)();

export const getCreditRemittanceTypes = createAsyncAction(
  'GET_CREDIT_REMITTANCE_TYPES_REQUEST',
  'GET_CREDIT_REMITTANCE_TYPES_SUCCESS',
  'GET_CREDIT_REMITTANCE_TYPES_FAIL',
)();

export const getCreditRemittanceAccounts = createAsyncAction(
  'GET_CREDIT_REMITTANCE_ACCOUNTS_REQUEST',
  'GET_CREDIT_REMITTANCE_ACCOUNTS_SUCCESS',
  'GET_CREDIT_REMITTANCE_ACCOUNTS_FAIL',
)();

export const createCreditRemittanceRequest = createAsyncAction(
  'CREATE_CREDIT_REMITTANCE_REQUEST_REQUEST',
  'CREATE_CREDIT_REMITTANCE_REQUEST_SUCCESS',
  'CREATE_CREDIT_REMITTANCE_REQUEST_FAIL',
)();

export const confirmCreditRemittanceRequest = createAsyncAction(
  'CONFIRM_CREDIT_REMITTANCE_REQUEST_REQUEST',
  'CONFIRM_CREDIT_REMITTANCE_REQUEST_SUCCESS',
  'CONFIRM_CREDIT_REMITTANCE_REQUEST_FAIL',
)();

export const getEarlyRepaySumByDate = createAsyncAction(
  'GET_EARLY_REPAY_SUM_BY_REQUEST',
  'GET_EARLY_REPAY_SUM_BY_SUCCESS',
  'GET_EARLY_REPAY_SUM_BY_FAIL',
)();

export function getCreditsAsync(operatorId, forceUpdate = false) {
  return (dispatch, getState) => {
    if (getState().user.creditsLoadedByOperatorId[operatorId] && !forceUpdate) {
      return Promise.resolve();
    }

    const getCardsAndContractsDetails = (cardsAndContracts) => {
      const failedCardsAndContracts = [];

      const { getCardDetails, getContractDetails } = ApiClient.contracts;
      const arrayOfGetDetailsPromises = cardsAndContracts.reduce((acc, { contract_number: id, contract_type_code: type }) => {
        const getDetails = type === 'C' ? getCardDetails : getContractDetails;
        acc.push(getDetails(id, operatorId));
        return acc;
      }, []);

      return Promise.allSettled(arrayOfGetDetailsPromises)
        .then((arrayOfDetails) => {
          let areAllSuccessfullyFetched = true;
          arrayOfDetails.forEach(({ status, value: { data: details } = {} }, i) => {
            cardsAndContracts[i].additionalInfo = {
              ...details,
              credit_sum: cardsAndContracts[i].credit_sum || details?.credit_sum,
              // credit_sum is in details for every contract except credit cards
            };

            if (status === 'rejected') {
              failedCardsAndContracts.push(cardsAndContracts[i]);
              areAllSuccessfullyFetched = false;
            }
          });

          if (areAllSuccessfullyFetched) {
            dispatch(showFailedToLoadData({}));
            return dispatch(getCredits.success(cardsAndContracts, { operatorId }));
          }

          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getCreditsAsync(operatorId, forceUpdate)),
            asyncStatusReducerName: 'fetchStatus',
            reducersNS: 'credits',
            withPageTitle: false,
          }));
          return dispatch(getCredits.failure({ data: cardsAndContracts, failedCardsAndContracts }, { operatorId }));
        })
        .catch((error) => dispatch(getCredits.failure(error)));
    };

    const prevError = getState().credits.fetchStatus.error;

    dispatch(getCredits.request(operatorId));

    if (prevError?.failedCardsAndContracts?.length > 0) {
      return getCardsAndContractsDetails(prevError.failedCardsAndContracts);
    }

    return ApiClient.contracts.getContracts()
      .then(({ data }) => getCardsAndContractsDetails(data))
      .catch((error) => {
        dispatch(getCredits.failure(error));
        dispatch(showFailedToLoadData({
          active: true,
          onRetry: () => dispatch(getCreditsAsync(operatorId, forceUpdate)),
          asyncStatusReducerName: 'fetchStatus',
          reducersNS: 'credits',
          withPageTitle: false,
        }));
      });
  };
}

export function getCreditPaymentScheduleAsync(contractNumber, contractGid, operatorId) {
  return (dispatch, getState) => {
    if (!contractNumber || !contractGid || !operatorId) {
      dispatch(getCreditPaymentSchedule.failure());
      return;
    }
    if (getState().credits.byId[contractNumber].paymentScheduleLoaded) {
      return;
    }
    dispatch(getCreditPaymentSchedule.request());
    ApiClient.contracts.getContractPaymentSchedule(contractGid, operatorId)
      .then(({ data }) => dispatch(getCreditPaymentSchedule.success(data, { contractNumber })))
      .catch((error) => dispatch(getCreditPaymentSchedule.failure(error)));
  };
}

export function getCreditRepaymentHistoryAsync(contractNumber, contractGid, operatorId) {
  return (dispatch, getState) => {
    if (!contractNumber || !contractGid || !operatorId) {
      dispatch(getCreditRepaymentHistory.failure());
      return;
    }
    if (getState().credits.byId[contractNumber].repaymentHistoryLoaded) {
      return;
    }
    dispatch(getCreditRepaymentHistory.request());
    ApiClient.contracts.getContractRepaymentHistory({ contractGid })
      .then(({ data }) => dispatch(getCreditRepaymentHistory.success(data, { contractNumber })))
      .catch((error) => dispatch(getCreditRepaymentHistory.failure(error)));
  };
}

export function getCreditOperationLogAppealsAsync(operatorId, contractNumber) {
  return (dispatch) => {
    if (!operatorId) {
      dispatch(getCreditOperationLogAppeals.failure());
      return;
    }
    // пока этот буфер проще убрать, чем поддерживать все кейсы которые хочет заказчик
    // if (getState().credits.operationLogAppealsFetchStatus.success && !reload) {
    //   return;
    // }

    dispatch(getCreditOperationLogAppeals.request());
    ApiClient.contracts.getContractOperationLogAppeals({ contractNumber })
      .then(({ data }) => {
        dispatch(showFailedToLoadData({}));
        dispatch(getCreditOperationLogAppeals.success(data));
      })
      .catch((error) => {
        dispatch(showFailedToLoadData({
          active: true,
          onRetry: () => dispatch(getCreditOperationLogAppealsAsync(operatorId)),
          asyncStatusReducerName: 'operationLogAppealsFetchStatus',
          reducersNS: 'credits',
          withPageTitle: true,
        }));
        dispatch(getCreditOperationLogAppeals.failure(error));
      });
  };
}

export function getCreditCardOperationsAsync(contractNumber, operatorId) {
  return (dispatch, getState) => {
    if (!contractNumber || !operatorId) {
      dispatch(getCreditCardOperations.failure());
      return;
    }
    if (getState().credits.byId[contractNumber].cardOperationsLoaded) {
      return;
    }

    dispatch(getCreditCardOperations.request());
    ApiClient.contracts.getContractCardOperations({ contractNumber, operatorId })
      .then(({ data }) => dispatch(getCreditCardOperations.success(data, { contractNumber })))
      .catch((error) => dispatch(getCreditCardOperations.failure(error)));
  };
}

export function getCreditRequisitesAsync(contractNumber, operatorId) {
  return (dispatch, getState) => {
    if (getState().credits.byId[contractNumber].requisitesLoaded) {
      return Promise.resolve();
    }

    dispatch(getCreditRequisites.request());
    return Promise.all([
      ApiClient.contracts.getContractRequisites(contractNumber, operatorId),
      ApiClient.contracts.getContractRequisitesDocUrl(contractNumber, operatorId),
    ])
      .then(([{ data }, { data: { url } }]) => dispatch(getCreditRequisites.success({ ...data, url }, { contractNumber })))
      .catch((error) => dispatch(getCreditRequisites.failure(error)));
  };
}

export function getCreditRemittanceAmountAsync(contractNumber) {
  return (dispatch, getState) => {
    if (getState().credits.byId[contractNumber].remittanceAmountLoaded) {
      return Promise.resolve();
    }

    dispatch(getCreditRemittanceAmount.request());
    return ApiClient.contracts.getContractRemittanceAmount({ contractNumber })
      .then(({ data }) => dispatch(getCreditRemittanceAmount.success(data, { contractNumber })))
      .catch((error) => dispatch(getCreditRemittanceAmount.failure(error, { contractNumber })));
  };
}

export function getCreditRemittanceTypesAsync(contractGid, contractNumber, operatorId) {
  return (dispatch, getState) => {
    if (getState().credits.byId[contractNumber].remittanceTypesLoaded) {
      return Promise.resolve();
    }

    dispatch(getCreditRemittanceTypes.request());
    return ApiClient.contracts.getContractRemittanceTypes({ contractGid, operatorId })
      .then(({ data }) => dispatch(getCreditRemittanceTypes.success(data, { contractNumber })))
      .catch((error) => dispatch(getCreditRemittanceTypes.failure(error, { contractNumber })));
  };
}

export function getCreditRemittanceAccountsAsync(contractGid, contractNumber, operatorId) {
  return (dispatch, getState) => {
    if (getState().credits.byId[contractNumber].remittanceAccountsLoaded) {
      return Promise.resolve();
    }

    dispatch(getCreditRemittanceAccounts.request());
    return ApiClient.contracts.getContractRemittanceAccounts({ contractGid, operatorId })
      .then(({ data }) => dispatch(getCreditRemittanceAccounts.success(data, { contractNumber })))
      .catch((error) => dispatch(getCreditRemittanceAccounts.failure(error, { contractNumber })));
  };
}

export function createCreditRemittanceRequestAsync(params) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(createCreditRemittanceRequest.request());
    return ApiClient.contracts.sendContractRemittanceRequest({ ...params, requestChannel })
      .then(() => dispatch(createCreditRemittanceRequest.success()))
      .catch((error) => dispatch(createCreditRemittanceRequest.failure(error)));
  };
}

export function confirmCreditRemittanceRequestAsync(params, contractNumber) {
  return (dispatch) => {
    dispatch(confirmCreditRemittanceRequest.request());
    return ApiClient.contracts.confirmContractRemittanceRequest(params)
      .then(({ data }) => dispatch(confirmCreditRemittanceRequest.success(data, { contractNumber })))
      .catch((error) => dispatch(confirmCreditRemittanceRequest.failure(error)));
  };
}

export function getEarlyRepaySumByDateAsync(contractNumber, onDate) {
  return (dispatch) => {
    dispatch(getEarlyRepaySumByDate.request());
    return ApiClient.contracts.getEarlyRepaySumByDate({ contractNumber, onDate })
      .then(({ data }) => {
        localStorage.setItem(`getEarlyRepaySumByDateDate-${contractNumber}`, Number(new Date()));
        localStorage.setItem(`getEarlyRepaySumByDate-${contractNumber}`, JSON.stringify(data));
        dispatch(getEarlyRepaySumByDate.success(data));
      })
      .catch((error) => dispatch(getEarlyRepaySumByDate.failure(error)));
  };
}
