import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import moment from 'moment';
import WarningIcon from '../../../../assets/svg/status/status-warning-new.svg';
import FormControl from '../../../../ui-kit/form-control/FormControl';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import postToNativeApp from '../../../../utils/postToNativeApp';
import DateInputField from '../../../common/forms/fields/DateInputField';
import useMounted from '../../../common/hooks/useMounted';
import useUser from '../../../common/hooks/useUser';
import AlertIcon from '../../../../assets/svg/alert-icon.svg';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import { formatCurrency } from '../../../../utils/format';
import { Button } from '../../../../ui-kit';
import { getEarlyFullRepaymentCalcAsync, sendEarlyFullRepaymentRequestAsync } from '../../../../actions/early-payment';
import { useAsyncStatus, useQueryParams } from '../../../common/hooks';

const initialFormErrors = {
  ferDate: 'Выберите дату',
};

const validationSchema = yup.object().shape({
  ferDate: yup.object().required(),
});

const perMode1FieldsConfig = [
  {
    label: 'Номер договора',
    key: 'contract_number',
  },
  {
    label: 'Дата досрочного погашения',
    key: 'early_payment_date',
  },
  {
    label: 'Сумма полного погашения',
    key: 'fullSum',
    resolveF: ({ fer_sum: ferSum, pure_fer_amount: pureFerAmount }) => ferSum || pureFerAmount,
    type: 'amount',
  },
  {
    label: 'Остаток на счёте',
    key: 'rest_sum_with_hold',
    resolveF: ({ rest_sum_with_hold: restSumWithHold }) => restSumWithHold,
    // isVisible: ({ rest_sum_with_hold: restSumWithHold }) => restSumWithHold >= 0,
    type: 'amount',
  },
  {
    label: 'Сумма к оплате с&nbsp;учетом остатка',
    key: 'earlyRepaySumWithHold',
    resolveF: ({
      fer_sum_with_rest: ferSumWithRest,
      fer_amount: ferAmount,
      pure_fer_amount: pureFerAmount,
      accountHoldListText,
      isFullBlock,
    }, type) => {
      if (type === 'A') return ferSumWithRest;
      return isFullBlock || accountHoldListText ? pureFerAmount : ferAmount;
    },
    isVisible: ({ fer_sum_with_rest: ferSumWithRest, fer_amount: ferAmount }, type) => {
      if (type === 'A') return typeof ferSumWithRest !== 'undefined';
      return typeof ferAmount !== 'undefined';
    },
    type: 'amount',
  },
];

const fieldsConfig = [
  {
    label: 'Основной долг',
    key: 'loan_debt_sum',
    type: 'amount',
  },
  {
    label: 'Срочные проценты',
    key: 'interest_sum',
    type: 'amount',
  },
  {
    label: 'Просроченный долг',
    key: 'debt_overdue_sum',
    type: 'amount',
  },
  {
    label: 'Просроченные проценты',
    key: 'interest_overdue_sum',
    type: 'amount',
  },
  {
    label: 'Штрафы',
    key: 'penalty_sum',
    type: 'amount',
  },
  {
    label: 'Госпошлина',
    key: 'state_duty',
    type: 'amount',
  },
  {
    label: 'Итого к оплате',
    key: 'fer_sum',
    isVisible: ({ loanDebtSum, interestSum }) => (typeof loanDebtSum !== 'undefined' && loanDebtSum !== null) && (typeof interestSum !== 'undefined' && interestSum !== null),
    type: 'amount',
  },
];

const EarlyFullPayment = (props) => {
  const { creditId, setInformerLoading, setInformerProps, additionalAlert } = props;
  const user = useUser();
  const { operator_id: operatorId } = user;
  const isMountedRef = useMounted();
  const dispatch = useDispatch();
  const sessionToken = window.sessionStorage.getItem('token');

  const [requestLoading, setRequestLoading] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [showCalculatedData, setShowCalculatedData] = useState(false);
  const [calcedData, setCalcedData] = useState({});
  const [selectedDate, setSelectedDate] = useState({ needTouch: false, date: null });
  const sendRarlyFullRepaymentStatus = useAsyncStatus((state) => state.earlyPayment.sendRarlyFullRepaymentFetchStatus);
  const [params, updateParams] = useQueryParams();

  const {
    credit,
    availableWithoutRequestDates,
    availableOnRequestDates,
    earlyFullRepaymentCalc,
    sendRarlyFullRepayment,
    ferRequestErrorCode,
    // ferRequestStatusCode,
    isRepaymentDebtOverdue,
    isAvailable,
    isRepaymentAnyday,
    earlyFullRepaymentDocumentLink,
    calcFerDateWithoutRequest,
    accountHoldListText,
    isFullBlock,
    earlyRepaySum,
    earlyRepayAttemptsCount,
  } = useSelector((state) => ({
    credit: state.credits.byId[creditId],
    availableWithoutRequestDates: state.earlyPayment.ferAvailability[creditId]?.availableWithoutRequestDates,
    availableOnRequestDates: state.earlyPayment.ferAvailability[creditId]?.availableOnRequestDates,
    earlyFullRepaymentCalc: state.earlyPayment.earlyFullRepaymentCalc[creditId] || JSON.parse(localStorage.getItem(`earlyFullRepaymentResponse-${creditId}`)) || {},
    sendRarlyFullRepayment: state.earlyPayment.sendRarlyFullRepayment[creditId] || {},
    ferRequestErrorCode: state.earlyPayment.earlyFullRepaymentCalc[creditId]?.error_code,
    // ferRequestStatusCode: state.earlyPayment.earlyFullRepaymentCalc[creditId]?.status_code,
    isRepaymentDebtOverdue: state.earlyPayment.ferAvailability[creditId]?.isRepaymentDebtOverdue,
    isAvailable: state.earlyPayment.ferAvailability[creditId]?.isAvailable,
    earlyFullRepaymentDocumentLink: state.earlyPayment.sendRarlyFullRepayment[creditId]?.request_user_document_mobile_url,
    isRepaymentAnyday: state.earlyPayment.ferAvailability[creditId]?.isRepaymentAnyday,
    calcFerDateWithoutRequest: state.earlyPayment.ferAvailability[creditId]?.calc_fer_date_without_request,
    accountHoldListText: state.earlyPayment.ferAvailability[creditId]?.accountHoldListText,
    isFullBlock: state.earlyPayment.ferAvailability[creditId]?.isFullBlock,
    earlyRepaySum: state.earlyPayment.ferAvailability[creditId]?.early_repay_sum,
    earlyRepayAttemptsCount: state.earlyPayment.ferAvailability[creditId]?.early_repay_attempts_count,
  }));

  // const dateInParams = useMemo(() => (params.ferDate ? moment(params.ferDate, 'DD.MM.YYYY') : null), [params.ferDate]);
  const initialFormValues = useMemo(() => ({
    ferDate: params.ferDate ? moment(params.ferDate, 'DD.MM.YYYY') : null,
  }), [params.ferDate]);

  const startInterval = () => {
    const intervalId = setInterval(() => {
      const earlyFullRepaymentResponseDate = localStorage.getItem(`earlyFullRepaymentResponseDate-${creditId}`);
      const secondsDifference = (new Date() - Number(earlyFullRepaymentResponseDate)) / 1000;
      if (earlyFullRepaymentResponseDate && secondsDifference > 600) {
        setShowCalculatedData(false);
        localStorage.removeItem(`earlyFullRepaymentResponse-${creditId}`);
        localStorage.removeItem(`earlyFullRepaymentResponseDate-${creditId}`);
        setCalcedData({});

        // if (availableOnRequestDates.length === 1) {
        //   dispatch(getEarlyFullRepaymentCalcAsync(creditId, operatorId, availableOnRequestDates[0]));
        //   startInterval();
        // }

        updateParams();
        setSelectedDate({ needTouch: false, date: params.ferDate });
      }

      if (!localStorage.getItem(`earlyFullRepaymentResponse-${creditId}`)) {
        clearInterval(intervalId);
      }
    }, 5000);
  };

  const isRepaymentAvailable = isAvailable === 1 && availableWithoutRequestDates?.length > 0 && typeof isRepaymentAnyday !== 'undefined';
  const { additionalInfo, contract_type_code: type } = credit;
  const submitRequest = (date) => {
    setRequestLoading(true);
    setCalcedData({});
    setShowCalculatedData(true);
    startInterval();
    dispatch(getEarlyFullRepaymentCalcAsync(creditId, operatorId, date));
  };

  const handleSubmit = useCallback(({ ferDate }) => {
    if (!requestLoading) {
      updateParams({ ferDate: ferDate.format('DD.MM.YYYY') });
      submitRequest(ferDate.format('DD.MM.YYYY'));
      setSelectedDate((prev) => ({ ...prev, needTouch: true }));
    }
  }, [requestLoading]);

  const sendRequest = useCallback(() => {
    setRequestLoading(true);
    dispatch(sendEarlyFullRepaymentRequestAsync(creditId, operatorId, earlyFullRepaymentCalc.fer_sum, availableOnRequestDates));
  }, [creditId, operatorId, earlyFullRepaymentCalc, availableOnRequestDates]);

  const getAdditionalInfo = useCallback(() => {
    if (isAvailable === 1) {
      if (availableOnRequestDates?.length > 0 && !availableWithoutRequestDates?.length && !earlyRepaySum && earlyRepayAttemptsCount > 0) {
        return `
          Погашение ранее даты платежа по&nbsp;графику возможно при оформлении
          услуги &laquo;Свобода платежа&raquo; в&nbsp;разделе &laquo;Услуги и опции&raquo;
        `;
      }
      if (availableWithoutRequestDates?.length > 0 && !isRepaymentDebtOverdue) {
        if (isRepaymentAnyday === 1) {
          return `
          По договору подключен сервис «Свобода платежа». Полное досрочное погашение доступно без&nbsp;предварительного
          уведомления Банка в&nbsp;любой рабочий день. Расчёт производится до&nbsp;
          ${availableWithoutRequestDates[availableWithoutRequestDates.length - 1]} включительно.<br/><br/>
          Денежные средства необходимо перевести самостоятельно на&nbsp;счёт, открытый в&nbsp;РОСБАНК Авто.
          Списание произойдёт не&nbsp;позднее следующего рабочего дня после зачисления денежных средств на&nbsp;счёт
        `;
        }
        return `
        Полное досрочное погашение доступно без&nbsp;предварительного уведомления Банка до&nbsp;${calcFerDateWithoutRequest} включительно.<br/><br/>
        Денежные средства необходимо перевести самостоятельно на&nbsp;счёт, открытый в&nbsp;РОСБАНК Авто.
        Списание произойдёт не&nbsp;позднее следующего рабочего дня после зачисления денежных средств на&nbsp;счёт.<br/><br/>
        После указанной даты полное досрочное погашение возможно только по&nbsp;заявлению и&nbsp;в&nbsp;дату платежа по&nbsp;кредиту
      `;
      }
      if (isRepaymentDebtOverdue === 1) {
        return `Полное досрочное погашение доступно без&nbsp;предварительного уведомления Банка.
        <br/><br/>
        Погашение просроченной задолженности произойдёт не позднее следующего рабочего дня после зачисления денежных средств на счёт`;
      }
    }
    return `Полное досрочное погашение доступно без&nbsp;предварительного уведомления Банка
      до&nbsp;${calcFerDateWithoutRequest} включительно и&nbsp;может быть выполнено
      не&nbsp;позднее следующего рабочего дня после поступления денежных средств на&nbsp;счёт.
      После указанной даты полное досрочное погашение возможно только по&nbsp;заявлению и&nbsp;в&nbsp;дату платежа по&nbsp;кредиту`;
  }, [isAvailable, availableWithoutRequestDates, isRepaymentAnyday, isRepaymentDebtOverdue, additionalInfo, calcFerDateWithoutRequest]);

  const handleChange = (changes) => {
    const date = changes.format('DD.MM.YYYY');

    setSelectedDate((prev) => {
      if (prev.date !== date) {
        setShowCalculatedData(false);
        return { needTouch: false, date };
      }

      return prev;
    });
  };

  useEffect(() => {
    if (availableWithoutRequestDates?.length === 0) {
      if (availableOnRequestDates?.length !== 0 && Object.keys(earlyFullRepaymentCalc).length === 0) {
        setShowPageLoader(true);
        submitRequest(availableOnRequestDates[0]);
      }
    }
    if (earlyFullRepaymentCalc.successed) {
      setShowCalculatedData(true);
      setCalcedData(earlyFullRepaymentCalc);
      setRequestLoading(false);
      if (localStorage.getItem(`earlyFullRepaymentResponse-${creditId}`)) {
        setShowCalculatedData(true);
      }
    } else {
      setRequestLoading(false);
    }

    if (params.ferDate && Object.keys(earlyFullRepaymentCalc).length !== 0) {
      setSelectedDate({ needTouch: true, date: params.ferDate });
      setShowCalculatedData(true);
    } else {
      setSelectedDate({ needTouch: false, date: null });
    }
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      if (earlyFullRepaymentCalc.successed) {
        setCalcedData(earlyFullRepaymentCalc);
      }

      if (earlyFullRepaymentCalc.processLoading === false) {
        setShowPageLoader(false);
        setRequestLoading(false);
      }
    }
  }, [earlyFullRepaymentCalc.processLoading]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (sendRarlyFullRepayment.success) {
        // dispatch(getCreditsAsync(operatorId, true)); //TODO check if something is broken
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: 'Отследить статус заявки вы&nbsp;можете в&nbsp;журнале обращений',
          type: 'success',
        }));
      } else if (sendRarlyFullRepayment.status === 403 && sendRarlyFullRepayment.error_message === 'Forbidden') {
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: 'Досрочное погашение временно недоступно. Пожалуйста, повторите попытку позднее',
        }));
      } else if (!sendRarlyFullRepayment.success && sendRarlyFullRepayment.failure) {
        setInformerProps((prev) => ({
          ...prev,
          active: true,
          paragraph: sendRarlyFullRepayment.failure,
          type: 'error',
        }));
      }
    }
    setInformerLoading(false);
  }, [sendRarlyFullRepayment?.processLoading]);

  useEffect(() => {
    if (!earlyFullRepaymentCalc.error_code) {
      setRequestLoading(false);
    }
  }, [ferRequestErrorCode]);

  useEffect(() => {
    if (sendRarlyFullRepaymentStatus.success && earlyFullRepaymentDocumentLink) {
      const link = document.createElement('a');
      link.download = 'new_schedule.pdf';
      link.href = earlyFullRepaymentDocumentLink;
      link.click();
    }
  }, [sendRarlyFullRepaymentStatus.success]);

  const shouldDisableDate = useCallback((day) => !(availableWithoutRequestDates?.length > 0 ? availableWithoutRequestDates : availableOnRequestDates).includes(day.format('DD.MM.YYYY')), [availableWithoutRequestDates]);

  const goToPayment = useCallback((e) => {
    e.preventDefault();
    const { account_number, contract_currency: currency } = additionalInfo;
    const configToSend = {
      account_number,
      currency,
      sum: calcedData.fer_sum_with_rest,
    };

    postToNativeApp({
      type: 'goToPayment',
      payload: configToSend,
    });
  }, [calcedData.fer_sum_with_rest, additionalInfo]);

  return (
    <LoaderOrChildren loading={showPageLoader}>
      <div className={classnames('page-early-payment', { 'bottom-button': !earlyFullRepaymentCalc.processLoading && showCalculatedData })}>
        <div className="page-early-payment-content">
          {additionalAlert && (
            <div className="alert-block compact">
              <div className="alert-block-title">
                <div className="alert-icon-with-text">
                  <AlertIcon className="alert-icon" />
                  <span className="alert-block-title-text" dangerouslySetInnerHTML={{ __html: getAdditionalInfo() }} />
                </div>
              </div>
            </div>
          )}

          <Formik
            initialValues={initialFormValues}
            initialErrors={initialFormErrors}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
          >
            {({ isValid }) => (
              <>
                {(availableWithoutRequestDates?.length > 0 ||
                  (Object.values(calcedData).length > 0 && showCalculatedData && availableWithoutRequestDates?.length > 0)) && (
                  <Form>
                    {availableWithoutRequestDates?.length > 0 && (
                      <>
                        <LabelValue key="contractNumber" label="Номер договора" value={creditId} />
                        <FormControl noMarginTop label="Дата досрочного погашения">
                          <DateInputField name="ferDate" shouldDisableDate={shouldDisableDate} onChange={handleChange} />
                        </FormControl>
                        <Button
                          className="request-submit"
                          type="submit"
                          design="red"
                          size="l"
                          loading={requestLoading}
                          disabled={!isValid || (selectedDate.needTouch && !requestLoading)}
                        >
                          Рассчитать сумму
                        </Button>
                      </>
                    )}
                    {Object.values(calcedData).length > 0 && showCalculatedData && availableWithoutRequestDates?.length > 0 && (
                      <>
                        <div className="repayment-info-block-wrapper calculated-repayment-data">
                          {fieldsConfig.filter((item) => {
                            const { isVisible = () => true, requiredParams = [], resolveF } = item;
                            const data = { ...additionalInfo, ...calcedData };
                            if (!resolveF) return (typeof data[item.key] !== 'undefined') && (data[item.key] > 0);
                            return isVisible(data, type) && requiredParams.every((param) => typeof data[param] !== 'undefined');
                          }).map((item) => {
                            const { label, resolveF, type: fieldType } = item;
                            const data = { accountHoldListText, isFullBlock, ...additionalInfo, ...calcedData };
                            const value = resolveF ? resolveF(data, type) : data[item.key];
                            return (
                              <div className="repayment-info-block-row" key={label + value}>
                                <div className="repayment-info-block-label">{label}</div>
                                <div className="repayment-info-block-value">{fieldType === 'amount' ? formatCurrency(value) : value}</div>
                              </div>
                            );
                          })}
                        </div>

                        {typeof calcedData.fer_sum_with_rest !== 'undefined' && (
                          <div className="alert-block">
                            <div className="alert-block-title">
                              <div className="alert-icon-with-text">
                                <AlertIcon className="alert-icon" />
                                <span className="alert-block-title-text">
                                  Сумма досрочного погашения на {earlyFullRepaymentCalc.ferDate} с&nbsp;учётом
                                  остатка на&nbsp;счёте составит {formatCurrency(calcedData.fer_sum_with_rest)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {!additionalAlert && (
                          <Button
                            className="with-margin-top send-application-btn"
                            design="red"
                            loading={requestLoading}
                            disabled={isFullBlock}
                            size="l"
                            onClick={sendRequest}
                          >
                            Отправить заявку
                          </Button>
                        )}
                      </>
                    )}
                  </Form>
                )}
              </>
            )}
          </Formik>
          {!!(earlyFullRepaymentCalc.processLoading) === false && showCalculatedData && (
            <>
              <div className="early-full-payment-content">
                {!isRepaymentAvailable && !isRepaymentDebtOverdue && perMode1FieldsConfig.filter((item) => {
                  const { isVisible = () => true, requiredParams = [], resolveF } = item;
                  const data = { ...additionalInfo, ...calcedData };
                  if (!resolveF) return typeof data[item.key] !== 'undefined';
                  return isVisible(data, type) && requiredParams.every((param) => typeof data[param] !== 'undefined');
                }).map((item) => {
                  const { label, resolveF, type: fieldType, key } = item;
                  const data = { accountHoldListText, isFullBlock, ...additionalInfo, ...calcedData };
                  const value = (() => {
                    if (resolveF) return resolveF(data, type);
                    if (key === 'early_payment_date') return availableOnRequestDates?.[0];
                    return data[key];
                  })();

                  return (
                    <LabelValue key={label + value} label={label} value={fieldType === 'amount' ? formatCurrency(value) : value} />
                  );
                })}
                {!isRepaymentAvailable && !isRepaymentDebtOverdue && (type === 'A' || type === 'P' || type === 'D') && (
                  <div className="alert-block">
                    <div className="alert-block-title">
                      <div className="alert-icon-with-text">
                        <AlertIcon className="alert-icon" />
                        <span className="alert-block-title-text">
                          Заявление о досрочном погашении может быть подано в&nbsp;соответствующем процентном периоде
                          не&nbsp;менее чем&nbsp;за&nbsp;1(один) рабочий день до&nbsp;даты ближайшего платежа
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {type === 'A' && !(isAvailable === 1 && availableWithoutRequestDates?.length > 0 && isRepaymentDebtOverdue) && (
                  <div className="alert-block compact">
                    <div className="alert-block-title">
                      <div className="alert-icon-with-text">
                        <AlertIcon className="alert-icon" />
                        <span className="alert-block-title-text">
                          При недостаточности денежных средств досрочное погашение осуществляется в&nbsp;сумме остатка на&nbsp;счёте
                          после погашения ежемесячного платежа
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {!!accountHoldListText && (
                  <div className="alert-block">
                    <div className="alert-block-title">
                      <div className="alert-icon-with-text">
                        <WarningIcon className="warning-icon" />
                        <span className="alert-block-title-text">
                          {accountHoldListText}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isAvailable === 1 && isRepaymentDebtOverdue !== 1 && isRepaymentAvailable &&
          showCalculatedData && !requestLoading && sessionToken && (
          <Button
            className="send-application-btn"
            design="red"
            disabled={isFullBlock}
            size="l"
            onClick={goToPayment}
          >
            Перейти к оплате
          </Button>
        )}
        {!isRepaymentAvailable && !isRepaymentDebtOverdue && type !== 'D' && type !== 'P' && (
          <Button
            className="send-application-btn"
            design="red"
            loading={requestLoading}
            disabled={isFullBlock || isAvailable !== 1}
            size="l"
            onClick={sendRequest}
          >
            Отправить заявку
          </Button>
        )}
      </div>
    </LoaderOrChildren>
  );
};

export default EarlyFullPayment;
